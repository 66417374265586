.icon {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
}
.icon-sm {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
}
.icon-lg {
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
}
.icon-md {
  width: 60px;
  height: 60px;
  position: relative;
  display: inline-block;
}

.icon,
.icon-lg,
.icon-md,.icon-sm {
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    top: 0;
    background-size: contain;
  }
}


.speaker {
  &:before {
    background-image: url(../img/icons/speaker.svg);
  }
}
.football {
  &:before {
    background-image: url(../img/icons/football.svg);
  }
}
.horse_racing {
  &:before {
    background-image: url(../img/icons/horse_racing.svg);
  }
}
.cricket {
  &:before {
    background-image: url(../img/icons/cricket.svg);
  }
}
.cricket_dark {
  &:before {
    background-image: url(../img/icons/cricket_dark.svg);
  }
}
.tennis {
  &:before {
    background-image: url(../img/icons/tennis.svg);
  }
}
