.casinoButton {
  height: 50px !important;
}

body {
  .snipcss-LtoGs {
    background-color: #2e3439;
    border-radius: 8px;
  }

  .casino-bl-box {
    .back {
      background-color: #72bbef40;
      border: 2px solid var(--back);
      color: #d7d7d7;
    }
  }

  .poker-2020-grid-main {
    .casinoButton {
      height: 40px !important;
    }

    .poker-2020-grid-item {
      padding: 0;
    }
  }

  .b32-card-grid-text {
    height: 20px;
  }

  .b32-card-grid-odds {
    margin-top: -12px;

    .pattiButton {
      text-align: center !important;
    }
  }

  .main-grid {
    .text-back-lay {
      display: flex;
      align-items: flex-end;
    }

    .bottom-row-32 {
      .pattiButton {
        font-family: "Anonymous Pro";
        font-size: 60px !important;
        height: 80px !important;
        line-height: 50px;

        svg {
          font-size: 14px !important;
        }
      }
    }
  }

  .snipcss-EpXpc {
    background: #2e3439;
    padding: 5px;
    background-color: #2e3439;
    border-radius: 8px;

    .col-md-3 {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .casino-odds-box-bhav {
      width: 50%;
      text-align: center;
    }

    .casino-yn {
      .casino-bl-box {
        width: 50%;
      }
    }
  }

  .teen-patti-test-grid-main {
  }

  .teenpatti-bg {
    background: linear-gradient(
      90deg,
      #4c3d3b 0,
      #5a4e49 20%,
      #5f514d 40%,
      #5d4f48 60%,
      #584c47 80%,
      #564445 100%
    );
  }

  .chakra-tabs__tab[aria-selected="true"] {
    --tabs-color: #fdcf13;
    border-color: currentColor;
  }

  .casino-war-grid-main {
    .casino-war-grid-7 {
      height: 40px;
      margin-top: 4px;
    }

    .pattiButton {
      margin: 0;
    }
  }

  .worli-odd-box {
    background: #72bbef40;
    border: 2px solid #72bbef;

    .d-block {
      color: #d7d7d7;
    }

    .worli-odd {
      font-size: 35px;
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .black-images {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .suspended {
    img {
      margin: 0 auto;
    }
  }

  .teenpatti-bg {
    .pattiButton {
      margin-bottom: 5px;
    }
  }

  .pp-text {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .chakra-modal__content {
  }

  .dt-grid-top-head {
    display: flex;
    justify-content: space-between;

    .dt-grid-col-left,
    .dt-grid-col-right {
      width: 40%;
    }

    .dt-grid-col-center {
      flex: 1;
    }
  }

  .dtd-grid-main {
    display: flex;

    .dtd-grid-col {
      width: 50%;
    }
  }

  .dt-grid-4-col {
    display: flex;
    justify-content: center;

    .cardimg {
      margin-right: 0;
    }

    .dt-grid-item {
      padding: 0;
      width: 25%;
      /*  width: calc(25% - 5px); */

      .pattiButton,
      .casinoButton-pink {
        width: 100%;
      }
    }
  }

  .dtl-grid-main {
    background: #2e3439 !important;

    .pattiButton {
      height: 40px !important;
    }

    .war-flex {
      margin: 0;
      padding: 0;
      background-color: #3c444b85;
      color: #aaafb5;
      padding: 4px;
      height: 40px;
      margin-top: 8px;
      display: flex;
      align-items: center;
    }
  }

  .pattiButton,
  .css-q6jxby {
    color: #fff;
  }

  .card-game-box {
    .css-156dii0 {
      background-color: rgba(0, 0, 0, 0.2);
      height: 40px;
      padding-top: 0;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }

  .casino-detail {
    background: #2e3439;
    border-radius: 8px;

    .casino-nation-name {
      color: #aaafb5 !important;
    }

    .back {
      background-color: #72bbef40;
      border: 2px solid var(--back);
      color: #d7d7d7;
      height: 48px;
    }

    .lay {
      background-color: #f994ba40;
      border: 2px solid var(--lay);
      color: #d7d7d7;
      height: 48px;
    }
  }

  .bottom-row {
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.card-grid-main {
  .grid-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .card-col {
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0;
      margin: 0;
      margin-right: 10px;
      margin-bottom: 5px;
      height: 62px;
      width: 45px;
    }
  }
}

.main-grid {
  .pattiButton {
    margin-bottom: 5px;
  }

  .css-vwwdou {
    height: 34px;
    margin-top: 0;
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 205px;
  }

  .first-grid-item-casino-test {
    width: 205px;
  }
}

.row.row5 {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  body {
    .main-grid {
      .mobile-cards-fix {
        .b32-card-grid-odds {
          margin-top: -9px;
        }
      }
    }

    .cricket-2020-grid {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
      background-color: #2e3439;

      .cricket-2020-grid-item {
        width: 100%;
      }
    }

    .cricket-5-grid-main {
      .css-10f918e {
        flex-direction: column;

        .css-k008qs {
          width: 100%;
        }
      }
    }

    .total-count-box {
      display: flex;
      justify-content: space-between;

      span {
        border: 1px solid var(--text-fancy);
        font-size: var(--font-small);
        padding: 2px 10px;
      }
    }

    .casino-live-page {
      margin-top: -60px;

      .casino-game-img {
        height: 65px;
        object-fit: contain;
      }
    }

    .casino-nation-name {
      font-size: 11px;
    }

    .snipcss-EpXpc {
      .casino-bl-box-item {
        span {
          font-size: 12px;
        }
      }

      .casino-odds-box-bhav,
      .casino-bl-box-item {
        font-size: var(--font-small);
      }

      .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;

        .casino-box-row {
          display: flex;

          .casino-nation-name {
            width: calc(40% - 3px);
            justify-content: flex-start;
            background: #3c444b;
            padding-left: 4px;
          }

          .casino-bl-box {
            width: 60%;
          }
        }
      }

      .col-6.col-md-3.d-none-big {
        .casino-box-row .casino-nation-name {
          background: none;
        }

        .casino-bl-box-item {
          text-transform: uppercase;
          font-size: var(--font-small);
          color: var(--text-table);
        }
      }
    }

    .casino-war-tabs {
      .chakra-tabs__tab-panel {
        padding: 0;
      }
    }

    .war-flex {
      width: 100%;

      .war-text {
        font-size: 12px;
      }
    }

    .casino-war-mobile-row {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .pattiButton {
        width: 94px;
        min-width: 94px;
      }
    }

    .worli-left,
    .worli-right {
      width: 100%;
      margin: 0;
    }

    .worli-odd-box .worli-odd {
      font-size: 25px;
      height: 30px;
    }
  }

  .casino-box.low-high-box {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .casino-box.sidebets-box {
    width: 100% !important;
  }

  .casino-detail {
    .cards-top .cards-top-box {
      height: 80px;
    }

    .casino-card-item .card-image img {
      width: 35px;
      margin-right: 0;
    }

    .casino-box.cards-top {
      .row.row5 {
        margin-bottom: 15px;

        .col-3 {
          width: 50%;
        }
      }
    }

    .row {
      .col-3 {
        max-width: 100%;
        flex: inherit;
      }
    }

    .casino-nation-name {
      justify-content: flex-start;
    }

    .casino-box-row {
      display: flex;
      flex-wrap: inherit;
    }
  }

  .main-grid {
    .table-title {
      height: 39px;
      width: 105px;
    }

    .css-tijqx6 {
      padding-left: 30px;
    }
  }

  body {
    .dt-grid-top-head {
      width: 100%;

      .dt-grid-col-left,
      .dt-grid-col-right {
        width: 35%;
      }

      .dt-grid-col-left,
      .dt-grid-col-center,
      .dt-grid-col-right {
        display: flex;
        flex-direction: column;
        padding: 5px;

        .css-1heqzl,
        .css-1o4mfjc {
          padding: 0;
        }

        p {
          padding: 0;
        }
      }
    }

    .dtd-grid-main {
      flex-wrap: wrap;

      .dtd-grid-col {
        width: 100%;
      }
    }

    .content-box {
      margin-top: 20px;
    }

    // .card-grid-main{
    //     display: flex;
    //     flex-direction: column;
    //     width: 60%;
    // }
    .grid-mobile-size {
      display: flex;
      flex-direction: column;
      height: auto;

      .grid-col {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .card-col {
          margin: 7px;
        }
      }

      .grid-item-two {
        border-left: 0;
      }
    }
  }

  .grid-btn-group {
    .flex-group-btn {
      flex-wrap: wrap;
      height: auto;

      .dt2-dragonButton,
      .dt2-tigerButton {
        width: 50%;
      }

      .dt2-pairButton {
        width: 100%;
        margin-left: 0;
      }

      .dt2-tieButton {
        width: 80px;
        height: 80px;
        left: 50%;
      }
    }
  }

  .dtl-grid-main {
    .pattiButton {
      width: 100% !important;
    }
  }
}
