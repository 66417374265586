@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-dialog {
    width: 100%;
    margin: 0 auto;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.fade {
    transition: opacity .15s linear;
}

.modal {
    display: none;
    position: fixed;
    top: 35px;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 8px !important;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

.modal-open {
    overflow: hidden;
}

body.modal-open {
    padding-right: 8px !important;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-18: 18px;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --bg-table-header: #3c444b;
    --text-table-header: #AAAFB5;
    --text-fancy: #FDCF13;
}

.modal-content {
    display: flex;
    display: -ms-flexbox;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--bg-table);
    background-clip: padding-box;
    border: 0;
    border-radius: 0;
    outline: 0;
    max-height: calc(100vh - 50px);
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

*,
:after,
:before {
    box-sizing: border-box;
}

header {
    display: block;
}

.modal-header {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    border: 0;
    border-radius: 0;
    background-color: var(--bg-table-header);
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 8px;
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

.modal-body:-webkit-scrollbar {
    width: 8px;
}

.modal-body:-webkit-scrollbar-thumb {
    background-color: var(--bg-table-header);
}

.modal-body:-webkit-scrollbar-track {
    background: var(--bg-table);
}

h5 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--text-fancy);
    font-size: var(--font-18);
}

button {
    border-radius: 0;
    margin: 0;
    font-family: revert;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
    text-transform: none;
}

[type="button"],
button {
    -webkit-appearance: button;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

[type="button"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: var(--text-table-header);
    opacity: 1;
    text-shadow: none;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover {
    opacity: .75;
}

.casino-result-round {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row.row5 {
    margin-left: -5px;
    margin-right: -5px;
}

.col-12,
.col-lg-7 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 992px) {
    .col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
}

.row.row5>[class*="col-"],
.row.row5>[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
}

.col-12,
.col-lg-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {
    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
}

.casino-result-content {
    padding: 6px;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.casino-result-desc {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding: 6px;
    background-color: var(--bg-body);
    box-shadow: 0 0 4px -1px;
    margin-top: 10px;
}

.text-center {
    text-align: center !important;
}

.casino-result-content-item {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 49%;
}

.casino-result-content-diveder {
    width: 2px;
    height: 130px;
    background-color: var(--bg-table-header);
}

.casino-result-desc-item {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.casino-result-cards {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
}

.casino-result-desc-item div:first-child {
    width: 30%;
    text-align: right;
    margin-right: 2%;
    color: var(--text-body);
    opacity: .6;
}

.casino-result-desc-item div:last-child {
    width: 68%;
    text-align: left;
    word-break: break-all;
}

.casino-result-cards-item {
    margin-right: 5px;
    display: inline-block;
}

.d-inline-block {
    display: inline-block !important;
}

img {
    vertical-align: middle;
    border-style: none;
}

.casino-result-cards-item img {
    width: 35px;
    margin-bottom: 5px;
}

.casino-result-cards-item .winner-icon {
    height: auto;
    width: 60px;
    transition: 1.2s ease-in;
    animation-iteration-count: infinite;
    -webkit-transition: 1.2s ease-in;
    -moz-transition: 1.2s ease-in;
    -ms-transition: 1.2s ease-in;
    -o-transition: 1.2s ease-in;
}

h4 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.5rem;
}

.casino-result-cards-item:last-child {
    margin-right: 0;
}