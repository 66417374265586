@import url("//fonts.googleapis.com/css2?family=Noto+Sans");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .casino-center {
        width: calc(100% - 396px);
    }
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .main-container {
        width: 100%;
    }
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .sidebar-left~.main-container {
        width: calc(100% - 220px);
        margin-left: 220px;
    }
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    body {
        font-size: var(--font-13);
    }
}

body {
    margin: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-caption: 14px;
    --font-13: 13px;
    --font-bold: bold;
    --text-white: #ffffff;
    --bg-success: #03B37F;
    --bg-danger: #FC4242;
    --bg-tabs: #03b37f33;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
    --text-highlight: #ffffff;
}

.casino-detail {
    padding: 5px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-detail {
        padding: 5px;
    }
}

.casino-detail {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.casino-box {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.casino-box.low-high-box {
    width: 49%;
    margin-right: 2%;
}

.casino-box.sidebets-box {
    width: 49%;
}

.cards-top {
    width: 100%;
}

.casino-box.cards-box {
    width: 100%;
}

.casino-box:last-child {
    margin-right: 0;
}

.suspended {
    position: relative;
    pointer-events: none;
}

.casino-box .low-odds {
    border: 5px solid var(--bg-danger);
    padding: 6px;
    width: 33.33%;
    height: 76px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.suspended:before {
    content: "";
    background-image: url(https://sitethemedata.com/v108/static/front/img/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    pointer-events: none;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
}

.suspended:after {
    content: "";
    background-color: #373636d6;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: not-allowed;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.low-odds.suspended:after {
    border-radius: 0;
}

.text-center {
    text-align: center !important;
}

.lucky7-card {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    width: 33.33%;
    background-color: var(--bg-tabs);
    padding: 6px;
}

.casino-box .high-odds {
    border: 5px solid var(--bg-success);
    padding: 6px;
    width: 33.33%;
    height: 76px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.high-odds.suspended:after {
    border-radius: 0;
}

.lucky7-extra-bets {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    width: 100%;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container-fluid-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.w-100 {
    width: 100% !important;
}

.casino-odds {
    font-weight: var(--font-bold);
    text-align: center;
    width: 100%;
    line-height: 18px;
    position: relative;
}

.casino-buttons {
    text-align: center;
    line-height: 18px;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
}

.d-none {
    display: none !important;
}

.casino-book {
    text-align: center;
    font-size: var(--font-caption);
    line-height: 18px;
    color: var(--text-highlight);
    margin-top: 2px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.lucky7-card img {
    height: 60px;
}

.lucky7-extra-bets-item-container {
    width: 23.5%;
    margin-right: 2%;
    margin-bottom: 5px;
}

.lucky7-extra-bets-item-container:nth-child(4n) {
    margin-right: 0;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row.row5 {
    margin-left: -5px;
    margin-right: -5px;
}

.mt-1 {
    margin-top: .25rem !important;
}

.casino-cards {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.lucky7-extra-bets-item {
    background-color: var(--bg-tabs);
    padding: 6px;
    border-radius: 8px;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .lucky7-extra-bets-item {
        padding: 2px;
    }
}

.lucky7-extra-bets-item.suspended:after {
    border-radius: 8px;
}

.col-6,
.col-md-3 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

@media (min-width: 768px) {
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.row.row5>[class*="col-"],
.row.row5>[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
}

.casino-card-item {
    margin-right: 0;
    width: auto;
    margin-bottom: 5px;
    min-width: 60px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-card-item {
        min-width: 45px;
    }
}

.casino-card-item:last-child {
    margin-right: 0;
}

.lucky7-extra-bets-item .casino-book {
    color: var(--text-white);
}

.casino-card-item .card-image {
    display: inline-block;
    cursor: pointer;
}

.card-image.suspended:after {
    border-radius: 0;
}

.casino-buttons img {
    height: 25px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-buttons img {
        height: 22px;
    }
}

.casino-card-item .card-image img {
    width: 40px;
    height: 56px;
   
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-card-item .card-image img {
        width: 35px;
    }
}

.cards-top .cards-top-box {
    padding: 5px;
    border: 5px solid var(--bg-tabs);
    cursor: pointer;
    height: 90px;
    justify-content: center;
    align-items: flex-start;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .cards-top .cards-top-box {
        height: 90px;
    }
}

.cards-top-box.suspended:after {
    border-radius: 0;
}

.cards-top .casino-card-item {
    display: inline-block;
    width: auto;
    margin-right: 5px;
    margin-bottom: 0;
    min-width: 35px;
}

.cards-top .casino-card-item:nth-child(3n) {
    margin-right: 0;
}