.suspendedtext {
    position: relative;
    pointer-events: none
}

.suspendedtext:after {
    content: attr(data-title);
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.7);
    /*  border-radius: 5px; */
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    color: var(--text-red);
    font-size: 12px;
    font-weight: var(--font-bold);
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
    pointer-events: none
}