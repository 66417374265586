@import url("//fonts.googleapis.com/css2?family=Noto+Sans");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-detail {
    padding: 5px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-detail {
        padding: 5px;
    }
}

.baccarat .casino-detail {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: linear-gradient(90deg, rgb(28 55 125) 0, rgb(41 75 159) 20%, rgb(39 87 187) 40%, rgb(37 88 188) 60%, rgb(44 82 171) 80%, rgb(34 55 117) 100%);
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .casino-center {
        width: calc(100% - 396px);
    }
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .main-container {
        width: 100%;
    }
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .sidebar-left~.main-container {
        width: calc(100% - 220px);
        margin-left: 220px;
    }
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
    flex-wrap: wrap;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    body {
        font-size: var(--font-13);
    }
}

body {
    margin: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-13: 13px;
    --text-white: #ffffff;
    --bg-tabs: #03b37f33;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
}

.baccarat-bets-odds {
    width: 75%;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.baccarat-odds {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    margin-bottom: 50px;
    padding-top: 15px;
}

.baccarat-bets {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    margin-top: 0;
    position: relative;
    padding-bottom: 30px;
}

.baccarat-odd-block {
    flex: 1 1 auto;
    margin-right: 16px;
    position: relative;
}

.baccarat-odd-block:last-child {
    margin-right: 0;
}

.player-pair {
    margin-right: 2px;
    width: 15%;
    height: 90px;
    margin-top: 15px;
    position: relative;
}

.player {
    width: 35%;
    height: 120px;
    position: relative;
}

.tie {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    top: 2px;
    z-index: 10;
}

.banker {
    width: 35%;
    height: 120px;
    position: relative;
}

.banker-pair {
    width: 15%;
    margin-left: 2px;
    height: 90px;
    margin-top: 15px;
    position: relative;
}

.suspended {
    position: relative;
    pointer-events: none;
}

.baccarat-odd-block .baccarat-odd-name {
    padding: 8px 2px;
    text-align: center;
    color: var(--text-white);
    background-color: var(--bg-tabs);
    cursor: pointer;
    border-radius: 4px;
    text-transform: uppercase;
}

.suspended:before {
    content: "";
    background-image: url(https://sitethemedata.com/v124/static/front/img/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    pointer-events: none;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
}

.suspended:after {
    content: "";
    background-color: #373636d6;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: not-allowed;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.baccarat-odd-block .baccarat-odd-name:hover {
    opacity: .8;
    cursor: pointer;
}

.d-none {
    display: none !important;
}

.baccarat-odd-block .baccarat-odd-val {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;
}

.baccarat-bets-name {
    height: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    flex-direction: column;
    color: var(--text-white);
    padding-left: 0;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

.player-pair .baccarat-bets-name {
    border-radius: 16px 0 0 16px;
    background-color: #509bff;
}

.player .suspended:before,
.player-pair .suspended:before {
    background-position: left 10px top 10px;
}

.player-pair .suspended:after {
    border-radius: 16px 0 0 16px;
}

.player-pair .baccarat-bets-name:hover {
    background: #509bffc9;
    cursor: pointer;
}

.player-pair .baccarat-bets-val {
    text-align: center;
    line-height: 18px;
}

.player .baccarat-bets-name {
    background-color: #509bff;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    align-items: flex-start;
    padding-left: 20px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .player .baccarat-bets-name {
        padding-left: 5px;
    }
}

.player .suspended:after {
    border-radius: 16px 0 0 16px;
}

.player .baccarat-bets-name:hover {
    background: #509bffc9;
    cursor: pointer;
}

.player .baccarat-bets-val {
    text-align: center;
    line-height: 18px;
}

.tie .baccarat-bets-name {
    background-color: #11b24b;
    border-radius: 50%;
    border: 2px solid #000;
}

.tie .suspended:after {
    border-radius: 50%;
}

.tie .baccarat-bets-name:hover {
    background: #0c9a1d;
    cursor: pointer;
}

.tie .baccarat-bets-val {
    text-align: center;
    line-height: 18px;
}

.banker .baccarat-bets-name {
    background-color: #d3393d;
    align-items: flex-end;
    padding-right: 20px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .banker .baccarat-bets-name {
        padding-right: 10px;
    }
}

.banker .suspended:before,
.banker-pair .suspended:before {
    background-position: right 10px top 10px;
}

.banker .suspended:after {
    border-radius: 0 16px 16px 0;
}

.banker .baccarat-bets-name:hover {
    background-color: #d3393ddb;
    cursor: pointer;
}

.banker .baccarat-bets-val {
    text-align: center;
    line-height: 18px;
}

.banker-pair .baccarat-bets-name {
    border-radius: 0 16px 16px 0;
    background-color: #d3393d;
}

.banker-pair .suspended:after {
    border-radius: 0 16px 16px 0;
}

.banker-pair .baccarat-bets-name:hover {
    background-color: #d3393ddb;
    cursor: pointer;
}

.banker-pair .baccarat-bets-val {
    text-align: center;
    line-height: 18px;
}

.player-pair .baccarat-bets-name div {
    margin-bottom: 0;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.player .baccarat-bets-name div {
    margin-bottom: 0;
}

.tie .baccarat-bets-name div {
    margin-bottom: 0;
}

.banker .baccarat-bets-name div {
    margin-bottom: 0;
}

.banker-pair .baccarat-bets-name div {
    margin-bottom: 0;
}

.player span {
    margin-left: 3px;
}

.banker span {
    margin-left: 3px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.player img {
    height: 60px;
}

.suspended img {
    position: relative;
    z-index: 10;
    cursor: not-allowed;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .player img {
        height: 32px;
    }
}

.banker img {
    height: 60px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .banker img {
        height: 32px;
    }
}

@media screen and (max-width: 767px) {
    .baccarat-odd-block .baccarat-odd-name {
        font-size: small;
    }

    .baccarat-bets-name {
        font-size: small;
    }

    .tie {
        width: 80px;
        height: 80px;
        top: 15%;
    }

    .player img {
        height: 30px;
        width: 20px;
    }

    .banker img {
        height: 30px;
        width: 20px;
    }
}