@font-face {
    font-family: numeric;
    src: url(https://sitethemedata.com/v108/static/front/fonts/numeric.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .casino-center {
        width: calc(100% - 396px);
    }
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .main-container {
        width: 100%;
    }
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .sidebar-left~.main-container {
        width: calc(100% - 220px);
        margin-left: 220px;
    }
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    body {
        font-size: var(--font-13);
    }
}

body {
    margin: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-13: 13px;
    --text-yellow: #FDCF13;
    --bg-tabs: #03b37f33;
    --book-green: #39ff39;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
    --text-table-header: #AAAFB5;
}

.casino-detail {
    padding: 5px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-detail {
        padding: 5px;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

.cmeter-card-box {
    background-color: var(--bg-tabs);
    padding: 0 10px 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 16px;
}

.teen1daycasino-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.cmeter-card-low {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cmeter-card-high {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.teen1dayleft {
    width: 49%;
}

.teen1dayleft {
    background-color: var(--bg-table-header);
    color: var(--text-table-header);
    padding: 10px;
    cursor: pointer;
}

.teen1daycenter {
    width: 2px;
    background-color: grey;
}

.teen1dayright {
    width: 49%;
}

.teen1dayright {
    background-color: var(--bg-table-header);
    color: var(--text-table-header);
    padding: 10px;
    cursor: pointer;
}

.text-playerb {
    color: var(--text-yellow);
}

.cmeter-card-low>div:first-child {
    width: 10%;
    text-align: left;
}

.ml-2 {
    margin-left: .5rem !important;
}

.cmeter-card-low>div:last-child {
    width: 85%;
    text-align: center;
}

.cmeter-card-high>div:first-child {
    width: 10%;
    text-align: left;
}

.cmeter-card-high>div:last-child {
    width: 85%;
    text-align: center;
}

.text-center {
    text-align: center !important;
}

.cmeter .teen1dayleft>div:first-child {
    min-height: 56px;
}

.w-100 {
    width: 100% !important;
}

.cmeter .teen1dayright>div:first-child {
    min-height: 56px;
}

.text-success {
    color: var(--book-green) !important;
}

.numeric {
    font-family: numeric;
    font-size: 30px;
    letter-spacing: 2px;
}

b {
    font-weight: bolder;
}

.p-2 {
    padding: .5rem !important;
}

.suspended {
    position: relative;
    pointer-events: none;
}

.casino-cards {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.suspended:before {
    content: "";
    background-image: url(https://sitethemedata.com/v108/static/front/img/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    pointer-events: none;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
}

.suspended:after {
    content: "";
    background-color: #373636d6;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: not-allowed;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

img {
    vertical-align: middle;
    border-style: none;
}

.cmeter-card-box img {
    width: 30px;
    margin-right: 5px;
}

.casino-card-item {
    margin-right: 5px;
    width: auto;
    margin-bottom: 5px;
    min-width: 60px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-card-item {
        min-width: 50px;
    }
}

.casino-card-item:last-child {
    margin-right: 0;
}

.d-none {
    display: none !important;
}

.text-left {
    text-align: left !important;
}

.casino-card-item .card-image {
    display: inline-block;
    cursor: pointer;
}

.casino-card-item .card-image img {
    width: 50px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-card-item .card-image img {
        width: 40px;
    }
}

.casino-card-item .card-image img {
    height: auto;
    width: 45px;
}