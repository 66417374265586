@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .casino-center {
        width: calc(100% - 396px);
    }
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .main-container {
        width: 100%;
    }
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .sidebar-left~.main-container {
        width: calc(100% - 220px);
        margin-left: 220px;
    }
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    body {
        font-size: var(--font-13);
    }
}

body {
    margin: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-caption: 14px;
    --font-odds: 16px;
    --font-small: 12px;
    --font-13: 13px;
    --font-bold: bold;
    --bg-bet: #03B37F52;
    --back: #72BBEF;
    --back-hover: #4FA1DC;
    --lay: #F994BA;
    --lay-hover: #E16F9A;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
}

.casino-detail {
    padding: 5px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-detail {
        padding: 5px;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

.text-right {
    text-align: right !important;
}

@media only screen and (min-width: 768px) {
    .d-none-big {
        display: none !important;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row.row5 {
    margin-left: -5px;
    margin-right: -5px;
}

.mt-2 {
    margin-top: .5rem !important;
}

.col-6,
.col-md-3 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

@media (min-width: 768px) {
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.row.row5>[class*="col-"],
.row.row5>[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
}

.col-12,
.col-md-4 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 768px) {
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.col-12,
.col-md-8 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

.casino-box-row {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding: 2px 0;
    align-items: center;
    position: relative;
}

.casino-nation-name {
    width: calc(100% - 148px);
    background-color: #3c444b85;
    color: #aaafb5;
    padding: 4px;
    position: relative;
}

.casino-nation-name {
    background-color: transparent;
    text-align: center;
    color: var(--text-table);
    width: 100%;
    background-color: transparent;
    text-align: center;
    color: var(--text-table);
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

}

.casino-bl-box {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.casino-bl-box {
    width: 100%;
}

.d-none {
    display: none !important;
}

.casino-yn {
    display: flex;
    width: 100%;
}

.rf-minheight {
    min-height: 20px;
}

.col-4 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-4 {
    padding: 0 !important;
}

img {
    vertical-align: middle;
    border-style: none;
}

.casino-nation-name img {
    width: 25px;
}

.back {
    background-color: var(--back);
}

.suspended {
    position: relative;
    pointer-events: none;
}

.casino-bl-box-item {
    width: 72px;
    margin-right: 4px;
    border-radius: 4px;
    color: var(--text-table);
    text-align: center;
    height: 32px;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
}

.casino-bl-box-item {
    width: calc(50% - 2px);
    height: 40px;
    position: relative;
}

.back {
    background-color: #72bbef40;
    border: 2px solid var(--back);
    color: #d7d7d7;
}

.suspended:before {
    content: "";
    background-image: url(https://sitethemedata.com/v108/static/front/img/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    pointer-events: none;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
}

.suspended:after {
    content: "";
    background-color: #373636d6;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: not-allowed;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.back:hover {
    background-color: var(--back-hover);
}

.back:hover {
    border: 1px solid var(--back);
}

.lay {
    background-color: var(--lay);
}

.casino-bl-box-item:last-child {
    margin-right: 0;
}

.lay {
    background-color: #f994ba40;
    border: 2px solid var(--lay);
    color: #d7d7d7;
}

.lay:hover {
    background-color: var(--lay-hover);
}

.lay:hover {
    border: 1px solid var(--lay);
}

.race20 .casino-yn>div {
    width: 50% !important;
    margin-right: 1%;
    text-align: center;
    position: relative;
}

.casino-yn .casino-odds-box-bhav {
    flex-direction: row;
}

.col-4 .casino-box-row {
    border-right: 1px solid var(--bg-bet);
    padding-right: 10px;
    padding-left: 10px;
}

.col-4:last-child .casino-box-row {
    border-right: 0;
}

.casino-bl-box-item>span {
    display: block;
    width: 100%;
    line-height: 14px;
    font-size: 16px;
    font-weight: 700;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-bl-box-item span {
        font-size: var(--font-small);
    }
}

.casino-bl-box-item .casino-box-odd {
    font-weight: var(--font-bold);
    font-size: var(--font-odds);
    height: 16px;
    line-height: 16px;
    margin-bottom: 2px;
    width: 100%;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-bl-box-item .casino-box-odd {
        font-size: var(--font-caption);
    }
}

b {
    font-weight: bolder;
}

.win-with .casino-bl-box-item {
    width: 100%;
}