@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&display=swap');

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

* {
    box-sizing: border-box;
}

* {
    outline: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

body {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    color: var(--site-color);
}

*,
:hover {
    outline: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --theme1-bg: #000000;
    --theme2-bg: #ffc629;
    --primary-color: #fff;
    --secondary-color: #000;
}

:root {
    --dark-grey: #ccc;
    --text-success: #169733;
    --text-danger: #b71e2d;
    --site-color: #000;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card {
    border-radius: 0;
    border: 0;
}

.card-header {

    margin-bottom: 0;
    background-color: #ECC94B !important;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card-header {
    padding: 6px;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    color: var(--secondary-color);
}

.card-header h4 {
    color: black;
}

.acc-date {
    font-weight: bold;
}

.acc-search::placeholder {
    font-weight: bold;
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.container-fluid-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.card-body {
    padding-top: 10px;
    padding-bottom: 10px;
}

h4 {
    margin-top: 0;
    margin-bottom: .5rem;
}

h4 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h4 {
    font-size: 1.5rem;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.card-header h4 {
    font-size: 18px;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row.row5 {
    margin-left: -5px;
    margin-right: -5px;
}

.mt-2 {
    margin-top: .5rem !important;
}

.col-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.row.row5>[class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.col-12 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group {
    position: relative;
}

button {
    border-radius: 0;
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn {
    border-radius: 0;
}

.btn-primary {
    background-color: var(--theme1-bg);
    border-color: var(--theme1-bg);
    color: var(--primary-color);
}

button:not(:disabled) {
    cursor: pointer;
}

.btn:hover {
    color: #212529;
    text-decoration: none;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-primary:hover,
.btn-primary:active {
    background-color: var(--theme1-bg) !important;
    border-color: var(--theme1-bg) !important;
    color: var(--primary-color) !important;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive {
    margin-bottom: 1rem;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    list-style: none;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.mx-datepicker {
    position: relative;
    display: inline-block;
    width: 210px;
    color: #73879c;
    font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei', sans-serif;
}

select {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

table {
    border-collapse: collapse;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table {
    margin-bottom: 0;
}

.table-responsive>.table-bordered {
    border: 0;
}

.table-responsive>.table {
    margin-bottom: 0;
}

li {
    list-style: none;
}

.mx-datepicker * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mx-input-wrapper {
    position: relative;
}

.mx-datepicker-popup {
    position: absolute;
    margin-top: 1px;
    margin-bottom: 1px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    z-index: 1000;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.form-control {
    border: 0;
    border-bottom: 1px solid var(--theme2-bg);
    border-radius: 0;
}

input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input {
    overflow: visible;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link {
    background-color: var(--dark-grey);
    border-color: var(--dark-grey);
    color: var(--site-color);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    border-radius: 0;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

[type="button"],
button {
    -webkit-appearance: button;
}

[type="button"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-item.active .page-link {
    background-color: var(--theme2-bg);
    border-color: var(--theme2-bg);
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.page-item:last-child .page-link {
    border-radius: 0;
}

.mx-input {
    height: 38px !important;
}

.mx-input {
    display: inline-block;
    width: 100%;
    height: 34px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.mx-input-append {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    padding: 6px;
}

.mx-input-wrapper .mx-clear-wrapper {
    display: none;
}

.mx-input-wrapper:hover .mx-clear-wrapper {
    display: block;
}

.mx-input-wrapper:hover .mx-clear-wrapper+.mx-input-append {
    display: none;
}

.mx-calendar {
    float: left;
    color: #73879c;
    padding: 6px 12px;
    font: 14px/1.5 Helvetica Neue, Helvetica, Arial, Microsoft Yahei, sans-serif;
}

.report-container {
    margin-top: -15px;
    margin-left: -10px;
    width: 106%;
}

.report-container .table thead tr {
    background-color: #ECC94B !important;
    color: black
}

.snipcss0-8-527-528 {
    color: black;
}

.report-container .table thead tr th {

    color: black !important
}

.mx-input-icon {
    display: inline-block;
    width: 100%;
    height: 100%;
    font-style: normal;
    color: #555;
    text-align: center;
    cursor: pointer;
}

.mx-clear-icon:before {
    display: inline-block;
    content: '\2716';
    vertical-align: middle;
}

.mx-clear-icon:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

.mx-calendar-icon {
    width: 100%;
    height: 100%;
    color: #555;
    stroke-width: 8px;
    stroke: currentColor;
    fill: currentColor;
}

.mx-calendar * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mx-calendar-header {
    padding: 0 4px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    overflow: hidden;
}

.mx-calendar-content {
    width: 224px;
    height: 224px;
}

th {
    text-align: inherit;
}

.text-center {
    text-align: center !important;
}

.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table-bordered th {
    border: 1px solid #dee2e6;
}

.table-bordered th {
    border: 1px solid #aaa;
}

.table th {
    padding: 2px;
    vertical-align: middle;
}

.table th {
    white-space: nowrap;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table-bordered thead th {
    border-bottom-width: 2px;
}

.table thead th {
    border-bottom: 0;
    vertical-align: middle;
    font-size: 14px;
}

.report-container .table tr th {
    white-space: nowrap;
    padding: 5px 7px;
}

.text-right {
    text-align: right !important;
}

.table td {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered td {
    border: 1px solid #aaa;
}

.table td {
    padding: 2px;
    vertical-align: middle;
}

.report-container .table tr td {
    white-space: nowrap;
    padding: 5px 7px;
    font-size: small;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a {
    text-decoration: none;
}

.mx-icon-last-year {
    padding: 0 6px;
    font-size: 20px;
    line-height: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mx-icon-last-year {
    float: left;
}

.mx-calendar-header>a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a,
a:hover,
a:active {
    text-decoration: none;
}

.mx-calendar-header>a:hover {
    color: #419dec;
}

a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}

.mx-icon-last-month {
    padding: 0 6px;
    font-size: 20px;
    line-height: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mx-icon-last-month {
    float: left;
}

.mx-icon-next-year {
    padding: 0 6px;
    font-size: 20px;
    line-height: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mx-icon-next-year {
    float: right;
}

.mx-icon-next-month {
    padding: 0 6px;
    font-size: 20px;
    line-height: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mx-icon-next-month {
    float: right;
}

.mx-panel {
    width: 100%;
    height: 100%;
    text-align: center;
}

.mx-panel-date {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
}

.mx-panel-year {
    padding: 7px 0;
}

.text-success {
    color: #28a745 !important;
}

.text-success {
    color: var(--text-success) !important;
}

.text-danger {
    color: #dc3545 !important;
}

.text-danger {
    color: var(--text-danger) !important;
}

.mx-calendar-content .cell {
    vertical-align: middle;
    cursor: pointer;
}

.mx-panel-year .cell {
    display: inline-block;
    width: 40%;
    margin: 1px 5%;
    line-height: 40px;
}

.mx-calendar-content .cell.disabled {
    cursor: not-allowed;
    color: #ccc;
    background-color: #f3f3f3;
}

.mx-calendar-content .cell:hover {
    background-color: #eaf8fe;
}

.mx-calendar-content .cell.actived {
    color: #fff;
    background-color: #1284e7;
}

.mx-panel-month .cell {
    display: inline-block;
    width: 30%;
    line-height: 40px;
    margin: 8px 1.5%;
}

.mx-time-list {
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    height: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    overflow-y: auto;
}

.mx-time-list:first-child {
    border-left: 0;
}

.mx-time-list:-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.mx-time-list:-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.mx-time-list .cell {
    width: 100%;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
}

.mx-panel-date th {
    font-size: 12px;
    width: 32px;
    height: 32px;
    padding: 0;
    overflow: hidden;
    text-align: center;
}

.mx-panel-date td {
    font-size: 12px;
    width: 32px;
    height: 32px;
    padding: 0;
    overflow: hidden;
    text-align: center;
}

.mx-panel-date td.last-month {
    color: #ddd;
}

.mx-panel-date td.today {
    color: #2a90e9;
}

.mx-panel-date td.next-month {
    color: #ddd;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-NPQXB.style-NPQXB {
    width: auto;
}

#style-bytdZ.style-bytdZ {
    display: none;
}

#style-oxZKr.style-oxZKr {
    display: none;
}

#style-Vjvax.style-Vjvax {
    display: none;
}

#style-kNQgQ.style-kNQgQ {
    display: none;
}

#style-coCds.style-coCds {
    display: none;
}

#style-lOmvG.style-lOmvG {
    display: none;
}

#style-lHCds.style-lHCds {
    display: none;
}

#style-yt3CG.style-yt3CG {
    display: none;
}

#style-L4ELL.style-L4ELL {
    display: none;
}

#style-bp27k.style-bp27k {
    display: none;
}

#style-w4BLz.style-w4BLz {
    display: none;
}

#style-95hBW.style-95hBW {
    width: 33.3333%;
}

#style-C58In.style-C58In {
    width: 33.3333%;
}

#style-S5D6G.style-S5D6G {
    width: 33.3333%;
}

#style-RNXto.style-RNXto {
    width: auto;
}

#style-MGXJB.style-MGXJB {
    display: none;
}

#style-8KEr7.style-8KEr7 {
    display: none;
}

#style-Xf6wg.style-Xf6wg {
    display: none;
}

#style-67QAz.style-67QAz {
    display: none;
}

#style-ppFKl.style-ppFKl {
    display: none;
}

#style-2hlID.style-2hlID {
    display: none;
}

#style-6ODzl.style-6ODzl {
    display: none;
}

#style-L5cqq.style-L5cqq {
    display: none;
}

#style-Q1Bpq.style-Q1Bpq {
    display: none;
}

#style-oMkgp.style-oMkgp {
    display: none;
}

#style-ofp2p.style-ofp2p {
    display: none;
}

#style-OlIzF.style-OlIzF {
    width: 33.3333%;
}

#style-K8kdI.style-K8kdI {
    width: 33.3333%;
}

#style-JyWHz.style-JyWHz {
    width: 33.3333%;
}