.tab {
    border-right: 1px solid rgb(60, 68, 75) !important;
    z-index: 0;

}

div {
    color: aliceblue;
}



/* .tabwa:last-child {
    border-right: none;
} */