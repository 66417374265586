@import url("//fonts.googleapis.com/css2?family=Noto+Sans");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .casino-center {
        width: calc(100% - 396px);
    }
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .main-container {
        width: 100%;
    }
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .sidebar-left~.main-container {
        width: calc(100% - 220px);
        margin-left: 220px;
    }
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    body {
        font-size: var(--font-13);
    }
}

body {
    margin: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-caption: 14px;
    --font-odds: 16px;
    --font-small: 12px;
    --font-13: 13px;
    --font-bold: bold;
    --bg-tabs: #03b37f33;
    --back: #72BBEF;
    --back-hover: #4FA1DC;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
    --text-highlight: #ffffff;
    --text-fancy: #FDCF13;
}

.casino-detail {
    padding: 5px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-detail {
        padding: 5px;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

.mb-2 {
    margin-bottom: .5rem !important;
}

.casino-open-card-box {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--bg-tabs);
    padding: 10px;
    border-radius: 16px;
    margin-bottom: 10px;
}

.teenpattiopen .casino-open-card-box>div {
    width: 12.5%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    color: var(--text-fancy);
}

.teenpattiopen .casino-open-card-box>div>div {
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

b {
    font-weight: bolder;
}

img {
    vertical-align: middle;
    border-style: none;
}

img {
    height: 50px;
    margin-right: 5px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    img {
        height: 30px;
    }
}