@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.eot);
    src: url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.woff2) format("woff2"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.woff) format("woff"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.ttf) format("truetype"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.svg#fontawesome) format("svg");
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.eot);
    src: url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.woff2) format("woff2"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.woff) format("woff"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.ttf) format("truetype"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.svg#fontawesome) format("svg");
}

@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-video {
    height: 60vh !important;
    position: relative;
    background-image: linear-gradient(0deg, #100b1e 0, #160d27 26.42%, #11091d 48.45%, #030309 100%);
    margin-top: -35px;
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
    flex-wrap: wrap;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
}

.casino-video-cards {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, .6);
    padding: 5px 20px 5px 5px;
    display: inline-block;
    height: 155px;
    overflow: hidden;
    transition: 1s;
    width: auto;
    border-radius: 0 8px 8px 0;
}

.casino-video-cards {
    width: auto;
    height: auto;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.casino-video-cards.hide-cards {
    width: 0 !important;
    height: 80px;
}

.casino-video-cards-label {
    font-size: small;

}

.casino-card-img {
    width: 37px !important;
}

.multilevel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.inner-wrapper {
    display: flex;

}

.casino-cards-shuffle {
    position: absolute;
    right: 5px;
    top: 50%;
    width: auto !important;
    font-size: 30px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #b3b3b3;
}

.casino-video-cards-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: column;
    transition: 1s;
}

.fas {
    display: inline-block;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

.fa-grip-lines-vertical:before {
    content: "\f7a5";
}

.casino-video-cards-container>div {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 5px;
}

.casino-video-cards-container>div:last-child {
    margin-bottom: 0;
}

img {
    vertical-align: middle;
    border-style: none;
}

.casino-video-cards span img {
    margin-right: 8px;
    width: 50px;
    height: 52px;
}