@import url("//fonts.googleapis.com/css2?family=Noto+Sans");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.detail-page-container {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.fullwidthdetail-container .casino-container {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .fullwidthdetail-container .casino-container {
        width: calc(100% - 396px);
    }
}

.fullwidthdetail-container>div {
    display: flex;
    flex-wrap: wrap;
}

.fullwidthdetail-container {
    width: 100%;
    position: relative;
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .main-container {
        width: 100%;
    }
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .sidebar-left~.main-container {
        width: calc(100% - 220px);
        margin-left: 220px;
    }
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    body {
        font-size: var(--font-13);
    }
}

body {
    margin: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-caption: 14px;
    --font-small: 12px;
    --font-13: 13px;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --bg-table-header: #3c444b;
}

.banner {
    height: auto;
    border-radius: 8px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .banner {
        width: 100%;
    }
}

.banner {
    height: auto;
}

.banner.scorecard-banner {
    height: 115px;
    background-position: center;
    background-size: cover;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.scorecard {
    width: 100%;
    border-radius: 8px;
    background-color: var(--bg-table);
    position: absolute;
    font-size: var(--font-caption);
    max-width: 95%;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .scorecard {
        font-size: var(--font-13);
    }
}

.scorecard-row {
    padding: 2px 15px;
    border-bottom: 1px solid var(--bg-table-header);
}

.scorecard-row:last-child {
    border-bottom: 0;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row.row5 {
    margin-left: -5px;
    margin-right: -5px;
}

.col-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
}

.active-team {
    background-color: var(--bg-table-header);
}

.row.row5>[class*="col-"],
.row.row5>[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
}

@media (min-width: 992px) {
    .text-lg-right {
        text-align: right !important;
    }
}

.col-6,
.col-lg-8 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {
    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

.text-left {
    text-align: left !important;
}

.col-6,
.col-lg-4 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.text-right {
    text-align: right !important;
}

.mr-1 {
    margin-right: .25rem !important;
}

.ball-runs {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    width: 25px;
    border-radius: 50%;
    font-size: var(--font-small);
    background-color: #08c;
    color: #fff;
    text-align: center;
}

.ball-runs.six {
    background-color: #883997;
}

b {
    font-weight: bolder;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-eJNOe.style-eJNOe {
    background-image: url("https://sitethemedata.com/v109/static/front/img/events-banner/4.png");
}