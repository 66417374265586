@import "./icons.scss";
@import "./variables.scss";
@import "./mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

body {
  background-color: #494949 !important;

  .chakra-tabs {
    background: #383838;

    .chakra-tabs__tab {
      padding: 8px;
    }
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

table {
  tr {
    td {
      .chakra-text {
        font-weight: 400;
        font-size: 13px;
        line-height: 147.52%;
        /* or 19px */

        color: #c3c3c3;
      }

      .time {
        font-weight: 500;
        font-size: 13px;
        line-height: 147.52%;
        /* or 19px */

        color: #868585;
      }

      .oddsBoxb,
      .oddsBoxr {
        .chakra-text {
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          /* identical to box height, or 21px */
          padding: 0px;
          letter-spacing: 0.05em;

          color: #262626;
        }
      }
    }
  }
}

.chakra-wrap {
  .slide_image {
    border-radius: 10px;
    height: 74px;
    width: 137px;
  }
}

* {
  font-family: "Noto Sans";
}

.chakra-button.primary {
  background-color: $primaryYellow;

  color: #000;
}

.sport_title_box {
  display: flex;

  .icon {
    margin-right: 10px;
    height: 15px;
    width: 15px;
  }

  .horse_racing {
    height: 22px;
    width: 30px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #c3c3c3;
  }
}
.match-odds-accordion-panel {
  .title-back-lay {
    border-radius: 0;
    height: 30px;
    margin-right: 0;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.match-odds-accordion-panel {
  .back-lay-text-box {
    margin-right: 140px;
  }
}
.top_header {
  background: #333333;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    li {
      padding: 0 16px;

      a {
        font-size: 14px;
        color: #aaafb5;
        font-weight: 700;
      }
    }
  }

  .right_nav {
    li {
      svg {
        color: $primaryYellow;
        font-size: 20px;
      }

      a {
        color: $primaryYellow;
        text-decoration: underline;
      }

      &:last-child {
        padding-left: 0;
      }
    }
  }
}

.nav_menu_bar {
  background: #262626;
  padding: 6px 25px;

  .chakra-button.primary {
    width: 120px;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    border-radius: 64px;
    color: #262626;
  }
}

.logo_box {
  img {
    width: 100px;
    margin-right: 40px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #23292e;
  height: 60px;
  padding: 0 15px;

  .chakra-button {
    width: 120px;
  }

  .logo_box {
    img {
      height: 43px;
    }
  }
}

.announcement_box {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #2eae98;
  background: #1d453e;
  padding: 15px;
  display: flex;
  width: 100%;
  border-radius: 58px;
  align-items: center;
  margin-left: 60px;

  .icon {
    margin-left: 5px;
    height: 18px;
    width: 18px;
  }
}

.sidebar {
  padding: 0 10px;
  top: 34px;

  .chakra-accordion {
    .chakra-accordion__item {
      margin-bottom: 5px;

      .chakra-accordion__button {
        padding: 10px;
      }

      .chakra-accordion {
        background: #222;
        padding: 10px;
        margin-top: 7px;
        border-radius: 10px;

        .chakra-text {
          font-size: 14px;
          line-height: 17px;
          color: #c3c3c3;
          font-weight: 400;
        }

        .chakra-accordion__panel {
          background: #222;
        }
      }

      .chakra-accordion__item {
        margin-bottom: 10px;
      }
    }
  }

  .menu_box {
    background: #16191c;
  }

  .upcoming_title {
    color: #aaafb5;
    font-weight: bold;
    padding: 3px;
    width: 100%;
    background-color: #3c444b;
    font-size: 13px;
    margin-bottom: 15px;
  }

  .sports_title {
    color: $primaryYellow;
    font-size: 18px;

    margin-bottom: 15px;
  }

  .menu_box {
    a {
      color: #aaafb5;
      padding: 2px 12px;
      display: flex;
    }
  }
}

.sidebar::-webkit-scrollbar-button {
  width: 5px;
  height: 5px;
  background-color: black;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 10px;
}

.slick-arrow {
  display: none !important;
}

.oddsBox,
.oddsBoxb,
.oddsBoxr,
.oddsBoxe,
.oddsBoxH {
  height: 24px;
  width: 55px;
  color: black;
  border-radius: 4px;

  text-align: center;

  margin-right: 4px;
  cursor: pointer;
  position: relative;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
  padding: 0px;
}

.oddsBox:last-child,
.oddsBoxb:last-child,
.oddsBoxr:last-child,
.oddsBoxe:last-child,
.oddsBoxH:last-child {
  margin-right: 0;
}

.oddsBoxH {
  width: 55px;
  height: 38px;
  padding: 0px 0;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
}
.match-odds-accordion-panel,
.bookmaker-accordion-panel {
  .oddsBoxH {
    border-radius: 0;
    height: 45px;
    margin: 0;
    width: 70px;
  }
}
.chakra-card {
  background-color: transparent !important;
}
.bookmaker-accordion-panel {
  .soccer-tennis {
    margin-right: 141px;
  }
  .soccer-tennis-back-lay {
    width: 70px;
    height: 30px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px;
  }
  .back-lay-text-box-bookmaker {
    margin-left: -45px;
    .title-back-lay {
      width: 70px;
      height: 30px;
      border-radius: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -5px;
    }
  }
}
.oddsBoxb {
  background-color: rgb(114, 187, 239);
}

.oddsBoxr {
  background-color: rgb(249, 148, 186);
}

.icon-time,
.icon-live,
.icon-irun,
.icon-predict,
.icon-cashout {
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
  vertical-align: top;
}

.nation-name,
.row-title {
  width: calc(100% - 231px);
  padding-right: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: $secondarytextColor;
}

.row-title {
  width: calc(100% - 231px);
}

.casinoButton {
  background-color: #72bbef40 !important;
  border: 2px solid #72bbef !important;
  color: #d7d7d7;
  padding: 8px 10px;
  width: 100%;
  border-radius: 4px !important;
  padding: 8px 10px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  height: 40px !important;
}

.casinoButton-pink {
  height: 40px !important;
  padding: 8px 10px;
  width: 105px;
}

.pattiButton {
  background-color: #72bbef40 !important;
  border: 2px solid #72bbef !important;
  color: #d7d7d7;
  padding: 8px 10px;
  width: 100%;
  border-radius: 4px !important;
  padding: 8px 10px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  height: 40px !important;
  width: 105px;
}

.cardimg {
  width: 45px;
}

.chakra-modal__header {
  background: #383838;
  border-radius: 10px;
}

.chakra-modal__content-container {
  .chakra-modal__content {
    background: #494949;
    border-radius: 10px;

    .chakra-modal__body {
      background: transparent;
      border-radius: 10px;
    }
  }
}

.card {
  margin-bottom: 20px;

  .chakra-card__header {
    z-index: 1;
    background-color: #262626;
    border-radius: 10px 10px 0px 0px;
    padding: 6px;

    h5 {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      color: #ffffff;
    }
  }

  .chakra-card__body {
    background: #383838;
    padding: 17px 10px 10px;
    border-radius: 10px;
    margin-top: -7px;
  }

  .sports_title {
    margin-top: -7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #fdcf13;
  }

  .sport_title_box {
    display: flex;

    .icon {
      margin-right: 10px;
      height: 15px;
      width: 15px;
    }

    .horse_racing {
      height: 22px;
      width: 30px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      color: #c3c3c3;
    }
  }

  small {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    color: #c3c3c3;
  }
}

.market-title {
  background-color: var(--theme2-bg85);
  padding: 6px 8px;
  color: var(--secondary-color);
  font-size: 10px;
  font-weight: bold;
  margin-top: 35px;
}

.game-header {
  padding: 8px;
  overflow: hidden;
  -ms-flex-pack: justify;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--theme1-bg);
  border-radius: 2px 2px 0 0;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.4);
}

.market-title {
  background-color: var(--theme2-bg85);
  color: var(--secondary-color);
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.market-title > span:first-child {
  display: inline-block;
  max-width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.market-1 .market-title > span:first-child {
  max-width: calc(100% - 160px);
}

.game-heading {
  padding: 8px;
  text-transform: uppercase;
  font-weight: var(--font-bold);
  width: 100%;
  border-radius: 8px;
  background-color: var(--bg-sidebar);
  color: var(--text-sidebar);
  margin-bottom: 6px;
  font-size: small;
  background-color: #16191c;
}

.bl-box.no-val {
  border: 1px solid #3c444b;
  border-radius: 4px;
  height: 24px;
  width: 55px;
}

.bl-box {
  height: 24px;
  width: 60px;
  border-radius: 4px;
  padding: 5px 0;
  text-align: center;
  margin-right: 4px;
  float: left;
  cursor: pointer;
  position: relative;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

.grid-center {
  width: 2px;
  background-color: grey;
}

// .dragon-box {
//     width: 40%;
//     padding: 6px;
//     border: 2px solid var(--text-red);
//     background-color: #fc42422e;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     align-items: center;
//     min-height: 60px;
//     padding-right: 60px;
//     cursor: pointer;
// }

.search-box .form-group {
  position: relative;
  margin-right: 0;
}

.search-box .form-control {
  height: 48px;
  border-radius: 4px;
  background-color: var(--bg-body) !important;
  border-color: var(--bg-body) !important;
  color: var(--text-body);
}

.search-box .search-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

.search-list {
  position: fixed;
  left: 249px;
  top: 110px;
  width: 400px;
  border-radius: 8px;
  background-color: #16191c;
  /*  box-shadow: 0 5px 11px 0 rgb(0, 0, 0, .28); */
  z-index: 1030;
}

.search-list .search-content {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2e3439 #3c444b;
}

.search-list .search-content a:last-child .search-game {
  border-bottom: 0;
}

.search-list .search-title {
  font-weight: var(--font-bold);
  text-transform: uppercase;
  padding: 8px;
}

.search-list .search-content::-webkit-scrollbar {
  width: 8px;
}

.search-list .search-content::-webkit-scrollbar-track {
  background: #2e3439;
}

.search-list .search-content::-webkit-scrollbar-thumb {
  background-color: #3c444b;
}

.search-event-title {
  background-color: #2e3439;
  padding: 8px 16px;
  color: var(--text-table);
}

.search-game {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid #3c444b;
  color: var(--text-sidebar);
}

.search-game:hover {
  background-color: #3c444b;
  color: #aaafb5;
}

.search-game .search-game-time {
  font-size: var(--font-small);
}

.player-card {
  width: 40px;
  text-align: center;
  display: inline-block;
  margin-left: 5px;
}

.game-title.active {
  background: linear-gradient(#2c8268, var(--bg-sidebar));
}

.scorecard {
  background-color: transparent !important;
  max-width: 100% !important;
  border-radius: 0 !important;

  .col-6.text-left {
    font-size: 18px;
  }

  .col-6.text-right {
    font-size: 18px;
  }

  .score-next-row {
    font-size: 16px;
  }

  .ball-runs {
    height: 35px;
    line-height: 35px;
    width: 35px;
  }

  .active-team {
    background-color: transparent;
  }

  .scorecard-row {
    padding: 5px;
  }

  .score-next-row {
    margin-top: 5px;
  }

  .inner-next-row {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// .score-box{
//     iframe{
//         height:172px;
//         .score-footer{
//             height:103px;
//         }
//     }
// }
.score-box {
  iframe {
    height: 40vh;
  }
}
.grid-fancy-box {
  .fancy-exposure-text{
    font-size: small;
    margin-left: 40px;
  }
}
.game-page-box {
}

.chakra-accordion {
  .chakra-accordion__item {
    .chakra-accordion__button {
      background: linear-gradient(#2c8268, var(--bg-sidebar));

      p {
        color: #fff;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .match-odds-accordion-panel {
    .back-lay-text-box {
      margin-right: 0px;
    }
    .oddsBoxH,
    .oddsBoxb,
    .oddsBoxr {
      width: 52px;
      height: min-content;
    }
  }
  .bookmaker-accordion-panel {
    .oddsBoxH {
      height: 30px;
    }
  }
  .oddsBoxr .resize {
  }
}
@media only screen and (max-width: 600px) {
  .match-odds-box {
    .team-name {
      font-size: 14px;
    }
  }

  .match-list-items {
    margin: 0 !important;
  }

  .resize {
    margin-right: 0px;
  }

  .match-odds-accordion-panel,
  .bookmaker-accordion-panel {
    padding: 0 !important;

    .back-lay-text-box,
    .back-lay-text-box-bookmaker {
      margin-right: 60px;
    }
  }
  .match-odds-accordion-panel {
    .back-lay-text-box {
      margin-right: 0px;
    }
  }
  .bookmaker-accordion-panel {
    .back-lay-text-box-bookmaker {
      .title-back-lay {
        width: 60px;
        height: 30px;
      }
    }
  }
}
.soccer-tennis {
  margin-left: 227px;
  .oddsBoxb,
  .oddsBoxr {
    width: 70px;
  }
}

//responsive
@media (max-width: 767px) {
  .bookmaker-accordion-panel {
    .soccer-tennis-list-wrap{
      justify-content: flex-end ;
      
    }
    .soccer-tennis-back-lay {
      margin-left: 0px;
    }
    .soccer-tennis{
      
      margin-left: 0px;
    
      margin-right: 59px;
    }
  }
  .oddsBoxr {
    width: 53px;
  }

  .soccer-tennis {
    
    
    position: relative;
    
    .oddsBoxb,
    .oddsBoxr {
      width: 58px;
    }
  }
  .back-lay-text-box-bookmaker {
    margin-right: 59px;
  }
  .bet-for-mobile {
    .bb-bsh {
      height: auto;
    }
  }

  .chakra-wrap .slide_image {
    width: 47%;
  }

  body {
    .chakra-tabs .chakra-tabs__tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .rec-slider-container {
      margin: 0 0;
    }

    .d-20-flex {
      flex-wrap: wrap;
      height: auto;

      .dt2-dragonButton,
      .dt2-tigerButton {
        width: 50%;
      }

      .dt2-pairButton {
        width: 100%;
        margin: 10px 0;
      }

      .dt2-tieButton {
        left: 46%;
      }
    }

    .bet-box-right {
      display: none;
    }

    .css-r6z5ec {
      z-index: 9999;
    }

    .top_header,
    .sidebar,
    #main_wrap {
      display: none;
    }

    .mobile-menu-top {
      background: #16191c;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;

      .menu-btn {
        border: 0;

        &:hover,
        &:focus {
          background: transparent;
        }

        svg {
          font-size: 30px;
        }
      }

      .logo_box {
        margin-left: 5px;

        img {
          height: 28px;
        }
      }
    }

    .balance-flex {
      padding: 0 15px;
    }

    .announcement-flex {
      position: relative;
      top: 0;
      border-bottom: 0;
      height: 25px;
      padding: 0;

      .announcement_box {
        margin-left: 0;
        border-radius: 0;
      }
    }

    .content-box {
      padding: 0 5px;
      padding-top: 0;
    }

    .right-sidebar {
      display: none;
    }

    .middle-body {
      width: 100%;
    }
  }

  .footer-menu {
    flex-direction: column;
  }

  .chakra-slide {
    max-width: 70% !important;
    top: 50px !important;
    background: #23292e !important;

    .sidebar {
      display: block;
      top: 0;

      .logo-side-wrap,
      .side-input-flex {
        display: none;
      }
    }
  }

  .inner-pages-box {
    .title-text {
      font-size: 16px;
    }

    .input-search-box {
      flex-direction: column;
      width: 100%;

      div {
        width: 100%;
      }

      .chakra-select__icon-wrapper {
        width: auto;
      }

      .chakra-button {
        width: 100%;
      }
    }
  }

  .match-odds-item-wrap {
    flex-wrap: wrap;

    .match-odds-box {
      width: 100%;
    }
  }

  .grid-fancy-box {
    .fancy-exposure-text{
      font-size: xx-small;
      margin-left: 40px;
    }
  }

  .game-top-main {
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 0 !important;
  }

  .game-top-head-box {
    .chakra-text {
      font-size: 12px;
    }
  }

  .casino-list-grid {
    img {
      height: 94px;
    }
  }

  .announcement_box {
    margin-left: 0;
  }

  .suspended {
    position: relative;
    pointer-events: none;
  }

  .suspended:before {
    content: "";
    background-image: url(https://sitethemedata.com/v109/static/front/img/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    pointer-events: none;
    background-position: bottom 7.5px center;
  }

  .suspended:after {
    content: "";
    background-color: #373636d6;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: not-allowed;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    top: unset;
    bottom: 0;
    height: 32px;
    border-radius: 3px;
  }

  .bet-table {
    background-color: var(--bg-table);
    border-radius: 8px 8px 0 0;
    margin-top: 0;
    position: relative;
    transform: translateZ(0);

    .bet-table-body {
      position: relative;
      padding: 5px;
      max-height: 310px;
      overflow-x: hidden;
      overflow-y: auto;

      .bet-table-box {
        border-bottom: 1px solid #3c444b;
        background-color: var(--bg-table-header);
        padding: 4px;
        margin: 4px 0;

        .bet-table-row-header-mobile {
          display: flex;
          align-items: center;
          background-color: var(--bg-table);
          padding: 4px;
          margin-bottom: 3px;

          .team-name {
            margin-bottom: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            color: #fff;
            max-height: 16px;
            line-height: 16px;
            font-size: var(--font-13);
          }

          .team-name.team-event {
            font-size: 12px;
          }

          .game-title {
            width: calc(100% - 492px);
            display: flex;
            display: -webkit-flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            position: relative;
            border: 1px solid #9b9797;
            padding: 5px;

            .game-date {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: 70px;
              height: 34px;
              line-height: 17px;
              border-right: 1px solid var(--bg-table-header);
              padding-right: 0;

              .day {
                height: 22px;
                color: #fff;

                display: inline-block;
                min-width: 85px;
                margin-bottom: 0;
                position: relative;
                margin-bottom: 0;
                height: auto;
                min-width: unset;
                line-height: 14px;
              }
            }

            .game-name {
              vertical-align: top;
              width: calc(100% - 70px);
              padding-left: 10px;
              display: flex !important;
              flex-wrap: wrap;
              align-items: center;

              a {
                width: 100%;
              }

              .game-icons {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                width: 40%;
                flex-wrap: wrap;
                justify-content: flex-end;
              }
            }
          }
        }

        .bet-table-row {
          padding: 6px 8px;
          display: flex;
          display: -webkit-flex;
          flex-wrap: wrap;
          width: 100%;
          align-items: center;
          padding: 0;
          border-bottom: 0;

          .game-title {
            width: 100%;
            position: relative;
          }

          .game-date {
            padding-right: 22px;
            border-right: 1px solid #3c444b;
            width: 150px;
            display: flex;
            display: -webkit-flex;

            .day {
              height: 22px;
              color: var(--text-table);
              opacity: 0.6;
              display: inline-block;
              min-width: 85px;
              margin-bottom: 0;
              position: relative;
              margin-bottom: 0;
              height: auto;
              min-width: unset;
              line-height: 14px;
            }
          }

          .game-name {
            padding-left: 16px;
            width: calc(100% - 260px);
            vertical-align: top;
          }

          .game-icons {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: flex-end;
            width: 40%;
            flex-wrap: wrap;

            .game-icon {
              text-align: center;
              margin-right: 10px;
            }
          }

          .point-title {
            width: 32%;
            margin-left: 0;
            margin-right: 2%;
          }

          .point-title:last-child {
            margin-right: 0;
          }

          .point-title-header {
            color: var(--text-table-header);
            display: none;
          }

          .bl-box {
            height: 32px;
            width: 72px;
            border-radius: 4px;
            padding: 5px 0;
            text-align: center;
            margin-right: 4px;
            float: left;
            cursor: pointer;
            position: relative;
            -webkit-transition: color 0.5s;
            transition: color 0.5s;
            width: 48%;
            margin-top: 0;
            margin-right: 4%;
            background: #72bbef;
            color: #000;

            .odds {
              color: #000 !important;
            }
          }

          .bl-box:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 4px;
          }

          .bl-box span {
            height: 19px;
            color: var(--text-black);
            font-size: var(--font-caption);
            font-weight: var(--font-medium);
            text-align: center;
            height: 22px;
            font-size: 15px;
            font-weight: var(--font-bold);
          }

          .lay {
            background-color: var(--lay);
          }

          .bl-box:last-child {
            margin-right: 0 !important;
          }

          .bl-box.no-odds .odds {
            color: var(--text-highlight);
          }

          .bl-box .odds {
            height: 22px;
            color: var(--text-black);
            font-weight: var(--font-bold);
            text-align: center;
            font-size: var(--font-odds);
            font-size: 15px;
            font-weight: var(--font-bold);
            color: var(--text-highlight);
          }

          .point-title-header {
            color: var(--text-table-header);
            display: none;
          }

          .bl-box.no-val {
            border: 1px solid #3c444b;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .d-none-mobile {
    display: none !important;
  }

  .d-inline-block {
    display: inline-block !important;
  }

  .game-footer-page-box {
    width: 100% !important;
  }

  .match-odds-accordion-panel {
    .match-odds-item-wrap {
      flex-wrap: inherit;
    }

    .match-odds-flex-bb {
      flex-direction: column;
      align-items: flex-start;
    }

    .chakra-wrap__listitem {
      justify-content: flex-end;
      margin: 0;
    }

    .oddsBoxH {
      display: none;

      &:nth-child(3),
      &:nth-child(4) {
        display: flex;
      }
    }
  }

  .scorecard-banner {
  }

  .announcement_box {
    display: none;
  }

  .bookmaker-accordion-panel {
    padding-top: 0 !important;

    .match-odds-item-wrap {
      flex-wrap: inherit;

      .oddsBoxH {
        width: 58px;
        margin: 0;
        border-radius: 0;

        &:first-child,
        &:last-child {
          display: none;
        }
      }
    }

    .match-odds-flex-bb {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .fancy-grid-item {
    margin-right: 0px;
  }
  .bet-for-mobile {
    .chakra-accordion__item {
      .chakra-accordion__button {
        font-weight: 400;
        background: linear-gradient(#2c8268, var(--bg-sidebar));
        color: #fff;
        margin-top: 0;
      }
    }
  }

  .fancy-accordion-panel {
    padding: 0 !important;
.oddsBoxH{
  height: 49px;
}
    .css-nq1qnt {
      padding: 0px;
      height: auto;
      border: 1px solid #9b9797;
    }
  }

  .bet-table-header {
    margin-top: 10px;
    padding-top: 10px;

    .game-title {
      border-right: 1px solid #9b9797;
      font-size: 12px;
      margin: 0;

      &:first-child {
        color: #0fcf0f;
      }

      &:last-child {
        border-right: 0;
      }

      span {
        text-transform: uppercase;
      }
    }
  }

  .b-flex-exp {
    width: 100%;
    justify-content: flex-end !important;

    p {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .match-odds-box {
    padding-left: 2px;

    .chakra-image {
      display: none;
    }

    .chakra-text {
      font-size: 12px;
    }
  }

  .footer-social img {
    height: 25px;
    width: 25px;
  }

  .oddsBoxH {
    width: 40px;
  }

  .bet-table {
    .bet-table-header {
      .mobile-home-menu-flex {
        overflow-x: auto;

        .game-title {
          min-width: 80px;
          display: flex;
          justify-content: center;
          height: 32px;
        }
      }
    }
  }

  .oddsBoxH,
  .oddsBoxb {
    border-radius: 0;
    margin: 0;
    width: 52px;

    p {
      font-size: 12px;
    }
  }

  .oddsBoxr {
    border-radius: 0;
  }

  .chakra-table {
    tr {
      th {
        color: #fff;

        p {
          color: #fff;
        }
      }

      td {
        color: #fff;
        padding: 5px;

        p {
          color: #fff;
          padding: 0px;
        }
      }
    }
  }

  .bookmaker-wrap-b-l {
    justify-content: flex-end;
    margin-right: 51px !important;
    margin-bottom: -4px !important;

    .oddsBoxH,
    .oddsBoxb,
    .oddsBoxr {
      width: 58px;
    }
  }

  .chakra-wrap__list {
    .match-list-items {
      margin: 0;
    }
  }

  .bet-for-mobile {
    .chakra-accordion__item {
      margin-top: -1px;
    }
  }

  .chakra-accordion__panel {
    iframe {
      height: 26vh !important;
    }
  }

  .banner.scorecard-banner {
    margin-top: -9px;
  }
}

@media (max-width: 290px) {
  .chakra-accordion__panel {
    iframe {
      height: 25vh !important;
    }
  }

  .mobile-menu-top {
    padding: 5px 5px !important;

    .chakra-menu__menu-button {
      padding: 0;
      font-size: 12px;
      height: 30px;
    }
  }

  .game-top-main {
    .game-top-head-box {
      .chakra-text {
        font-size: 9px;
      }
    }

    span {
      font-size: 10px;
    }
  }

  .scorecard {
    background-color: transparent !important;
    max-width: 100% !important;
    border-radius: 0 !important;

    .active-team {
      background-color: transparent;
    }

    .scorecard-row {
      padding: 5px;
    }

    .score-next-row {
      margin-top: 5px;
    }

    .inner-next-row {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .balls {
        .ball-runs {
          height: 20px;
          width: 20px;
          font-size: 10px;
          line-height: 20px;
          margin-right: 1px !important;
        }
      }
    }
  }
}

.market-title {
  color: #fdcf13;
}

.bet-box-right {
  overflow-y: auto;
}

.bb-bsh::-webkit-scrollbar {
  width: 10px;
}
