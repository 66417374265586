@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.report-box {
    background-color: var(--bg-table);
    padding: 16px;
    min-height: calc(100vh - 60px);
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.report-container {
    width: 100%;
    padding-left: 6px;
    padding-right: 6px;
    position: relative;
}

.header-casino~.center-main-content {
    margin-top: 134px;
    min-height: calc(100% - 168px);
}

.main-container {
    position: relative;
    width: 100%;
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
    --text-highlight: #ffffff;
    --btn-primary: #03B37F;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    margin-top: 20px;
    margin-bottom: 20px;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.mt-2 {
    margin-top: .5rem !important;
}

.pagination {
    justify-content: center;
}

*,
:after,
:before {
    box-sizing: border-box;
}

li {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.page-link {
    display: block;
    position: relative;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--text-highlight);
    background-color: transparent;
    border: 0;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}

a[data-v-82963a40] {
    cursor: pointer;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-item.disabled .page-link {
    color: var(--text-table);
    pointer-events: none;
    cursor: auto;
    background-color: transparent;
    border-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a,
a:hover {
    text-decoration: none;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:active,
.page-link:hover {
    background-color: transparent;
    color: var(--text-highlight);
    box-shadow: none;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: var(--btn-primary);
    border-color: var(--btn-primary);
    border-radius: 50%;
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

/* Modal */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-dialog {
    width: 100%;
    margin: 0 auto;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.fade {
    transition: opacity .15s linear;
}

.modal {
    display: none;
    position: fixed;
    top: 35px;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 8px !important;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

.modal-open {
    overflow: hidden;
}

body.modal-open {
    padding-right: 8px !important;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-caption: 14px;
    --font-small: 12px;
    --font-18: 18px;
    --book-green: #39ff39;
    --back: #72BBEF;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-sidebar: #16191C;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
    --text-table-header: #AAAFB5;
    --text-fancy: #FDCF13;
}

.modal-content {
    display: flex;
    display: -ms-flexbox;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--bg-table);
    background-clip: padding-box;
    border: 0;
    border-radius: 0;
    outline: 0;
    max-height: calc(100vh - 50px);
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

*,
:after,
:before {
    box-sizing: border-box;
}

header {
    display: block;
}

.modal-header {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    border: 0;
    border-radius: 0;
    background-color: var(--bg-table-header);
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 8px;
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

.modal-body:-webkit-scrollbar {
    width: 8px;
}

.modal-body:-webkit-scrollbar-thumb {
    background-color: var(--bg-table-header);
}

.modal-body:-webkit-scrollbar-track {
    background: var(--bg-table);
}

h5 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--text-fancy);
    font-size: var(--font-18);
}

button {
    border-radius: 0;
    margin: 0;
    font-family: revert;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
    text-transform: none;
}

[type="button"],
button {
    -webkit-appearance: button;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

[type="button"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: var(--text-table-header);
    opacity: 1;
    text-shadow: none;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover {
    opacity: .75;
}

.mt-1 {
    margin-top: .25rem !important;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.mt-2 {
    margin-top: .5rem !important;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-height: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

.report-table {
    margin-top: 0;
    min-height: 400px;
}

.table-responsive {
    margin-bottom: 1rem;
}

.report-table.report-table-modal {
    min-height: unset;
}

.table-responsive:-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.table-responsive:-webkit-scrollbar-thumb {
    background-color: var(--bg-table-header);
}

.table-responsive:-webkit-scrollbar-track {
    background: var(--bg-table);
}

.col-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.text-right {
    text-align: right !important;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
    display: -ms-inline-flexbox;
    margin-right: 1rem;
    line-height: 24px;
}

.total-soda {
    float: right;
}

table {
    border-collapse: collapse;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: var(--text-table);
    border: 1px solid #3c444b;
    background-color: var(--bg-table);
    table-layout: fixed;
}

.table-responsive>.table {
    margin-bottom: 0;
}

input {
    margin: 0;
    font-family: revert;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

label {
    display: inline-block;
    margin-bottom: 8px;
    font-size: var(--font-small);
    color: var(--text-body);
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    color: var(--text-body);
    line-height: unset;
}

.custom-control-label:before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-control-label:before {
    background-color: var(--bg-table-header);
}

.custom-radio .custom-control-label:before {
    border-radius: 50%;
}

.custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: var(--bg-sidebar);
    background-color: var(--bg-sidebar);
    background-image: fill;
}

.custom-control-label:after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
    background-image: initial;
    background-position-x: 50%;
    background-position-y: center;
    background-size: 50% 50%;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23999'/%3e%3c/svg%3e");
}

.table thead {
    background-color: var(--bg-table-header);
    color: var(--text-table-header);
}

.mr-2 {
    margin-right: .5rem !important;
}

.text-success {
    color: var(--book-green) !important;
}

.back-border {
    border-left: 5px solid var(--back);
}

.lay-border {
    border-left: 5px solid #F994BA;
}

th {
    text-align: inherit;
}

.table th {
    padding: 4px;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border: 0;
    border-bottom: 1px solid #3c444b;
}

.table thead th {
    vertical-align: middle;
    border-bottom: 2px solid #dee2e6;
    border: 0;
}

.report-table .bet-nation {
    width: 150px;
}

.report-table .bet-user-rate {
    width: 110px;
}

.report-table .bet-amount {
    width: 170px;
}

.report-table .bet-date {
    width: 210px;
}

.report-table .bet-ip {
    cursor: pointer;
    width: 200px;
}

.report-table .bet-remark {
    width: auto;
    cursor: pointer;
}

.report-table-modal.report-table .bet-remark {
    width: 300px;
    cursor: pointer;
}

.table td {
    padding: 4px;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border: 0;
    border-bottom: 1px solid #3c444b;
}

.report-table .bet-nation div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.report-table .bet-user-rate div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.report-table .bet-amount div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.report-table .bet-date div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.report-table .bet-ip div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.report-table .bet-remark div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.report-table-modal.report-table .bet-remark div {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.d-inline-block {
    display: inline-block !important;
}

.vm {
    vertical-align: middle;
}

.d-inline {
    display: inline !important;
}

.bet-nation span {
    height: 19px;
    color: var(--text-table);
    font-size: var(--font-caption);
    letter-spacing: 0;
    line-height: 19px;
    max-width: calc(100% - 20px);
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

.custom-checkbox .custom-control-label:before {
    border-radius: .25rem;
}