@import url('https://fonts.googleapis.com/css?family=Noto+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
    flex-wrap: wrap;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-bold: bold;
    --text-white: #ffffff;
    --text-yellow: #FDCF13;
    --text-red: #FC4242;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-sidebar: #16191C;
    --text-sidebar: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
}

.right-sidebar {
    width: 350px;
    border-radius: 8px 0 0 0;
    /* background-color: var(--bg-sidebar); */
    padding: 16px;
    height: calc(100vh - 100px);
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
    overflow-x: hidden;
    position: relative;
}

.right-sidebar.casino-right-sidebar {
    width: 464px;
    background-color: var(--bg-table);
    padding: 4px;
}

.five-sidebar.right-sidebar {
    background-color: var(--bg-sidebar);
    padding: 8px;
}

@media only screen and (min-width: 1280px) {
    .casino-right-sidebar.right-sidebar {
        display: block;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

.right-sidebar:-webkit-scrollbar {
    width: 8px;
}

.right-sidebar:-webkit-scrollbar-thumb {
    background-color: var(--bg-table-header);
}

.right-sidebar:-webkit-scrollbar-track {
    background: var(--bg-table);
}

.casino-place-bet {
    width: 100%;
    border-radius: 8px;
    background-color: var(--bg-sidebar);
    color: var(--text-sidebar);
    margin-bottom: 6px;
}

.mt-2 {
    margin-top: .5rem !important;
}

.cricket-rule {
    font-weight: 700;
}

.casino-place-bet-title {
    padding: 8px;
    text-transform: uppercase;
    font-weight: var(--font-bold);
}

.casino-video-last-results {
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    display: -webkit-flex;
    align-content: flex-start;
    flex-wrap: wrap;
    transition: 1s;
    overflow: hidden;
}

.teen2sidebar .casino-video-last-results {
    width: 100%;
    height: auto;
    position: unset;
    top: unset;
    right: unset;
}

.text-center {
    text-align: center !important;
}

.card {
    display: flex;
    display: -ms-flexbox;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0;
    border-radius: 0;
}

.casino-video-last-results span {
    width: 35px;
    margin-left: 5px;
    margin-top: 5px;
    border-radius: 4px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background-color: #434343;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
}

.casino-video-last-results span.resulta {
    color: var(--text-red);
}

.teen2sidebar .casino-video-last-results span {
    flex: 1 1 0;
}

.casino-video-last-results span.resulttie {
    color: var(--text-white);
}

.casino-video-last-results span.resultb {
    color: var(--text-yellow);
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.casino-video-last-results a {
    width: 35px;
    margin-left: 5px;
    margin-top: 5px;
    border-radius: 4px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background-color: #434343;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
}

.casino-video-last-results a.result-more {
    width: 100%;
    line-height: 1.8;
    margin-right: 5px;
    margin-bottom: 5px;
    color: var(--text-white);
}

.teen2sidebar .casino-video-last-results a {
    flex: 1 1 0;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a,
a:hover {
    text-decoration: none;
}

.cricket-rule img {
    height: 40px !important;
    width: auto !important;
}



.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 6px;
    background-color: var(--bg-table);
    color: var(--text-table);
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.mt-1 {
    margin-top: .25rem !important;
}

.row.row5 {
    margin-left: -5px;
    margin-right: -5px;
}

.cricket-rule .row {
    align-items: center;
}

.col-4 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.row.row5>[class*="col-"],
.row.row5>[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
}

.col-3 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.text-right {
    text-align: right !important;
}

img {
    vertical-align: middle;
    border-style: none;
}

.cricket-rule img {
    height: 40px;
}

.ml-2 {
    margin-left: .5rem !important;
}