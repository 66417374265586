.casinoButton-pink {
  background-color: #f994ba40 !important;
  border: 2px solid #f994ba !important;
  margin-left: 10px;


}

.dt2-dragonButton {
  position: relative;
  width: 40%;
  padding: 6px;
  border: 2px solid red;
  background-color: #fc42422e;

  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding-right: 60px;
  cursor: pointer;
  margin-top: 15px;
}

.dt2-tieButton {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  left: 40%;
  transform: translateX(-50%);
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  background-color: #03b37f;
  color: white;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}

.dt2-tigerButton {
  position: relative;
  width: 40%;
  padding: 6px;
  border: 2px solid yellow;
  background-color: #fdcf132e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding-left: 60px;
  cursor: pointer;
  margin-top: 15px;
}

.dt2-pairButton {
  width: 18%;
  margin-left: 2%;
  background-color: #03b37f33;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  flex-wrap: wrap;
  cursor: pointer;
  border: 3px solid #0041a2;
  margin-top: 15px;
}

.dtl-img {
  width: 20px;
  margin-left: 2px;
  height: 25px;
}

.lucky7b-bg {
  background: linear-gradient(90deg, rgb(19 45 110) 0, rgb(34 72 164) 20%, rgb(42 83 179) 40%, rgb(42 81 183) 60%, rgb(43 81 183) 80%, rgb(28 66 152) 100%) !important;
}

.dt-1day-text {
  text-align: start;
  padding-top: 25px;
  color: gray.300;
  font-weight: bold;
  padding-left: 10px;

}

.table-title {
  color: gray.300;
  font-weight: bold;
  font-size: small;
  padding-top: 10px;
  text-align: center;
}

.war-title {
  text-align: center;
  font-weight: bold;
  color: gray.300;
  padding-top: 15px;
}

.war-text {
  text-align: start;
  color: gray.300;
  font-weight: bold;
}

.war-flex {

  background-color: #3c444b85;
  color: #aaafb5;
  padding: 4px;
  height: 40px;
  margin-top: 0;
}

.teen-1day-title {
  text-align: center;
  font-weight: bold;
  color: gray.300;
  padding-top: 15px;
}

.teen-1day-flex {
  height: fit-content;
  padding-left: 5px;
  margin-top: 10px;
  background-color: #00000047;
  margin-left: 5px;
}

/* cricket */
/* html {
    height: 100%;
  }
  
  body {
    height: 100%;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: #ffffff;
  }
  
  #main-holder {
    width: 30%;
    height: 60vh;
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    box-shadow: 2px 7px 12px 5px #d7d7d7;
}
  
  #login-error-msg-holder {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
  }
  
  #login-error-msg {
    width: 23%;
    text-align: center;
    margin: 0;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #8a0000;
    border: 1px solid #8a0000;
    background-color: #e58f8f;
    opacity: 0;
  }
  
  #error-msg-second-line {
    display: block;
  }
  
  #login-form {
    align-self: flex-start;
    display: grid;
    justify-items: center;
    align-items: center;
  }
  
  .login-form-field::placeholder {
    color: #3a3a3a;
  }
  
  .login-form-field {
    width: 300px;
    border: none;
    border-bottom: 1px solid #3a3a3a;
    margin-bottom: 20px;
    border-radius: 2px;
    outline: none;
    padding: 0px 0px 5px 5px;
}
  
  #login-form-submit {
    width: 100%;
    padding: 7px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    background-color: #00ad06;
    cursor: pointer;
    outline: none;
  }

  .remember{
    width: 100%;
    padding-bottom: 30px;
    padding-top: 20px;

  } */

.teen20left {
  width: 49%;
}

.score-box {
  position: relative;
  height: 64px;
  margin-top: 30px;
  padding: 0;
  background-image: url(https://sitethemedata.com/v108/static/front/img/balls/score-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 45px;
}

.team-score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
}

.text-center {
  text-align: center !important;
}

.ball-icon {
  position: absolute;
  left: 50%;
  top: -25px;
  height: 50px;
  transform: translateX(-50%);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .blbox {
    width: 150px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .ball-icon img {
    height: 50px;
  }
}


.blbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  width: 170px;
  height: 40px;
  transform: translateX(-50%);
  bottom: -15px;
}

.suspended:before {
  content: "";
  background-image: url(https://sitethemedata.com/v108/static/front/img/lock.svg);
  background-size: 17px 17px;
  filter: invert(1);
  background-repeat: no-repeat;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: center;
  pointer-events: none;
}

.casino-table .casino-detail .blbox .back,
.cricket20.casino-table .casino-detail .blbox .back:hover {
  border: 0;
  background-color: black;
  cursor: pointer;
  font-weight: 700;
  color: black
}

.casino-table.cricket20 .casino-detail .blbox .lay,
.casino-table.cricket20 .casino-detail .blbox .lay:hover {
  border: 0;
  background-color: #f994ba;
  cursor: pointer;
  font-weight: 700;
  color: black
}

.d-block {
  display: block !important;
  color: #000;
}

.mobile-color {
  color: #000 !important;
}

.suspended:after {
  content: "";
  background-color: #373636d6;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

/* five5-cricket */

bet-table-row {
  padding: 6px 8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.suspendedtext {
  position: relative;
  pointer-events: none;
}

.bl-box {
  width: 72px;
  height: 38px;
  padding: 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* poker-1-Day */

.poker-box-left {
  padding: 6px;
  padding-bottom: 0;
  border: 2px solid red;
  background-color: #fc42422e;
  min-height: 72px;
  /* width: 39%; */
  height: 50%;
}

.poker-box-right {
  padding: 6px;
  padding-bottom: 0;
  border: 2px solid yellow;
  background-color: #fdcf132e;
  min-height: 72px;
  /* width: 39%; */
  height: 50%;
}

.poker-box-center {
  width: 20%;
  background-color: #03b37f33;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 8px;
  height: 120px;
}

.poker-img {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.poker6-box {
  grid-template-columns: repeat(2, 1fr);
  background-color: #03b37f33;
  border-radius: 5px;
  height: 40px;
  padding: 0px 5px 5px 5px;
  margin-top: 10px;
}

.poker6-text-left {
  text-align: start;
  padding: 10px 0px 0px 5px;
  font-weight: bold;
  font-size: small;
}

.poker6-text-right {
  text-align: end;
  padding: 10px 5px 0px 0px;
  font-weight: bold;
  font-size: small;
}

/* baccart */

.baccarat-bg {
  background: linear-gradient(90deg, rgb(28 55 125) 0, rgb(41 75 159) 20%, rgb(39 87 187) 40%, rgb(37 88 188) 60%, rgb(44 82 171) 80%, rgb(34 55 117) 100%);
}

.baccarat-block-1 {
  height: 30px;
  width: 120px;
  flex: 1 1 auto;
  margin: 20px 15px 20px 15px;
  position: relative;
  box-sizing: border-box;
  display: block;
  line-height: 22px;
  background-color: #03b37f33;
  border-radius: 8px;
  padding-bottom: 0;
  color: #aaafb5;
  position: relative;
  transform: translateZ(0);
}

.baccarat-block-2 {
  height: 30px;
  width: 80px;
  flex: 1 1 auto;
  margin: 20px 15px 20px 15px;
  position: relative;
  box-sizing: border-box;
  display: block;
  line-height: 22px;
  background-color: #03b37f33;
  border-radius: 8px;
  padding-bottom: 0;
  color: #aaafb5;
  position: relative;
  transform: translateZ(0);
}

.baccarat-block-3 {
  height: 30px;
  width: 80px;
  flex: 1 1 auto;
  margin: 20px 15px 20px 15px;
  position: relative;
  box-sizing: border-box;
  display: block;
  line-height: 22px;
  background-color: #03b37f33;
  border-radius: 8px;
  padding-bottom: 0;
  color: #aaafb5;
  position: relative;
  transform: translateZ(0);
}

.baccarat-block-4 {
  height: 30px;
  width: 120px;
  flex: 1 1 auto;
  margin: 20px 15px 20px 15px;
  position: relative;
  box-sizing: border-box;
  display: block;
  line-height: 22px;
  background-color: #03b37f33;
  border-radius: 8px;
  padding-bottom: 0;
  color: #aaafb5;
  position: relative;
  transform: translateZ(0);
}

.baccarat-player-pair {
  background-color: #509bff;
  border-radius: 16px 0 0 16px;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  padding-left: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.baccarat-player {
  position: relative;
  width: 40%;
  padding: 6px;
  flex-wrap: wrap;
  background-color: #509bff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  align-items: flex-start;
  padding-left: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  padding-right: 60px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.baccarat-circle {
  position: absolute;
  background-color: #11b24b;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  left: 40%;
  transform: translateX(-50%);
  padding: 6px;
  display: flex;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  padding-left: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.baccarat-banker {
  position: relative;
  background-color: #d3393d;
  width: 40%;
  padding: 6px;
  align-items: flex-end;
  padding-right: 20px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  padding-left: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.baccarat-banker-pair {
  border-radius: 0 16px 16px 0;
  background-color: #d3393d;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  padding-left: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.baccarat-tie {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  left: 50%;
  transform: -50%;
  top: 2px;
  z-index: 10;
}

.baccarat2-block {
  height: 30px;
  width: 80px;
  flex: 1 1 auto;
  margin: 20px 15px 20px 15px;
  position: relative;
  box-sizing: border-box;
  display: block;
  line-height: 22px;
  background-color: #03b37f33;
  border-radius: 8px;
  padding-bottom: 0;
  color: #aaafb5;
  position: relative;
  transform: translateZ(0);
}

.teenpatti-bg {
  background-color: linear-gradient(90deg, rgb(80 59 49) 0, rgb(85 67 56) 20%, rgb(86 70 57) 40%, rgb(86 68 55) 60%, rgb(74 60 53) 80%, rgb(64 50 52) 100%);
}

.andar-cards-box {
  display: flex;
  padding: 10px;
  margin: 10px;
  justify-content: center;
  flex-wrap: wrap;
  border: 2px solid #fc424280;
  grid-template-columns: repeat(6, 1fr);
}

.bahar-cards-box {
  padding: 10px;
  display: flex;
  margin: 10px;
  justify-content: center;
  flex-wrap: wrap;
  border: 2px solid #fdcf1380;
  grid-template-columns: repeat(6, 1fr);
}

.andar-text {
  text-align: center;
  font-weight: bolder;
  font-size: large;
  color: red;
  padding-top: 10px;
}

.bahar-text {
  text-align: center;
  font-weight: bolder;
  font-size: large;
  color: yellow;
  padding-top: 10px;
}

.three-card-left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  padding: 10px;
  margin: 10px;
  background-color: #72bbef40;
  border: 2px solid #72bbef;
  grid-template-columns: repeat(8, 1fr);
}

.three-card-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  padding: 10px;
  margin: 10px;
  background-color: #f994ba40;
  border: 2px solid #f994ba;
  grid-template-columns: repeat(8, 1fr);
}

@font-face {
  font-family: timer;
  src: url(https://sitethemedata.com/v108/static/front/fonts/timer.woff2) format("woff2"), url(https://sitethemedata.com/v108/static/front/fonts/timer.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
  box-sizing: border-box;
  outline: 0 !important;
}

.casino-table {
  background-color: var(--bg-table);
  border-radius: 8px;
  padding-bottom: 0;
  color: var(--text-table);
  position: relative;
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.casino-container {
  width: 100%;
  min-height: calc(100vh - 100px);
}

.casino-center {
  width: calc(100% - 476px);
  margin-right: 6px;
  margin-left: 6px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .casino-center {
    width: calc(100% - 396px);
  }
}

.center-main-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding-top: 6px;
  margin-top: 60px;
}

.main-container {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .main-container {
    width: 100%;
  }
}

.sidebar-left~.main-container {
  width: calc(100% - 302px);
  margin-left: 302px;
  margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .sidebar-left~.main-container {
    width: calc(100% - 220px);
    margin-left: 220px;
  }
}

.wrapper {
  display: flex;
  display: -webkit-flex;
  position: relative;
}

body {
  margin: 0;
  font-family: "Noto Sans";
  font-size: var(--font-body);
  font-weight: 400;
  line-height: 22px;
  color: var(--text-body);
  text-align: left;
  background-color: var(--bg-body);
  letter-spacing: 0;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  body {
    font-size: var(--font-13);
  }
}

body {
  margin: 0;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
  --font-body: 16px;
  --font-13: 13px;
  --text-white: #ffffff;
  --bg-warning: #FDCF13;
  --bg-tabs: #03b37f33;
  --back: #72BBEF;
  --back-hover: #4FA1DC;
  --bg-body: #23292E;
  --text-body: #AAAFB5;
  --bg-table: #2E3439;
  --text-table: #AAAFB5;
  --bg-table-header: #3c444b;
  --text-highlight: #ffffff;
  --text-fancy: #FDCF13;
}

.casino-detail {
  padding: 5px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-detail {
    padding: 5px;
  }
}

*,
:after,
:before {
  box-sizing: border-box;
}

.casino-tabs {
  height: 50px;
  background-color: var(--bg-tabs);
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  position: relative;
  border-radius: 0 0 8px 8px;
}

.casino-tabs {
  height: auto;
  padding: 6px;
  border-radius: 0;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.casino-tabs .nav-tabs {
  border: 0;
  margin-left: 50px;
  margin-right: 50px;
  position: relative;
  flex-wrap: wrap;
  overflow: hidden;
  scroll-behavior: smooth;
  white-space: nowrap;
  margin: 0;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.fade {
  transition: opacity .15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.casino-tabs li {
  background-color: var(--bg-tabs);
  margin: 1px;
  flex: 1;
  padding: 0 5px;
  border-radius: 4px;
}

.worlibox {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  position: relative;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.casino-tabs .nav-tabs .nav-link {
  color: var(--text-white);
  padding-top: 0;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border: 0;
  text-align: center;
}

.casino-tabs .nav-tabs .nav-item .nav-link.active {
  color: var(--text-white);
  border-bottom: 3px solid var(--bg-warning);
  background-color: transparent;
}

.casino-tabs .nav-tabs .nav-item .nav-link {
  padding: 6px;
}

.casino-tabs .nav-tabs .nav-item .nav-link.active {
  border-bottom: 0;
  color: var(--text-fancy);
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a,
a:hover {
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: none;
}

.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.casino-tabs .nav-tabs .nav-item .nav-link:hover {
  color: var(--text-white);
}

.worli-left {
  width: 58%;
  margin-right: 2%;
  display: flex;
  flex-wrap: wrap;
}

.worli-right {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
}

.worli-full {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.worli-box-title {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.worli-box-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}

b {
  font-weight: bolder;
}

.back {
  background-color: var(--back);
}

.worli-odd-box {
  text-align: center;
  font-family: timer;
  height: 70px;
  color: var(--text-highlight);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.worli-left .worli-odd-box {
  width: calc(20% - 2px);
  margin-right: 2px;
}

.back {
  background-color: #72bbef40;
  border: 2px solid var(--back);
  color: #d7d7d7;
}

.back:hover {
  background-color: var(--back-hover);
}

.back:hover {
  border: 1px solid var(--back);
}

.worli-left .worli-odd-box:nth-child(5n) {
  margin-right: 0;
}

.worli-right .worli-odd-box {
  width: calc(50% - 2px);
  margin-right: 2px;
}

.worli-right .worli-odd-box:nth-child(2n) {
  margin-right: 0;
}

.worli-full .worli-odd-box {
  width: calc(20% - 2px);
  margin-right: 2px;
}

.worli-full .worli-odd-box:nth-child(5n) {
  margin-right: 0;
}

.sp .worli-right .worli-odd-box {
  height: 142px;
  width: 100%;
}

.dp .worli-right .worli-odd-box {
  height: 142px;
  width: 100%;
}

.trio .worli-full .worli-odd-box {
  width: 100%;
}

.suspended {
  position: relative;
  pointer-events: none;
}

.card56 .worli-right .worli-odd-box {
  height: 142px;
  width: 100%;
}

.card64 .worli-right .worli-odd-box {
  height: 142px;
  width: 100%;
}

.abr .worli-left .worli-odd-box {
  width: calc(33.33% - 2px);
}

.abr .worli-left .worli-odd-box:nth-child(3n) {
  margin-right: 0;
}

.abr .worli-right .worli-odd-box {
  width: 100%;
}

.abr .worli-right .worli-odd-box:last-child {
  margin-right: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
  box-sizing: border-box;
  outline: 0 !important;
}

.casino-table {
  background-color: var(--bg-table);
  border-radius: 8px;
  padding-bottom: 0;
  color: var(--text-table);
  position: relative;
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.casino-container {
  width: 100%;
  min-height: calc(100vh - 100px);
}

.casino-center {
  width: calc(100% - 476px);
  margin-right: 6px;
  margin-left: 6px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .casino-center {
    width: calc(100% - 396px);
  }
}

.center-main-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding-top: 6px;
  margin-top: 60px;
}

.main-container {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .main-container {
    width: 100%;
  }
}

.sidebar-left~.main-container {
  width: calc(100% - 302px);
  margin-left: 302px;
  margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .sidebar-left~.main-container {
    width: calc(100% - 220px);
    margin-left: 220px;
  }
}

.wrapper {
  display: flex;
  display: -webkit-flex;
  position: relative;
}

body {
  margin: 0;
  font-family: "Noto Sans";
  font-size: var(--font-body);
  font-weight: 400;
  line-height: 22px;
  color: var(--text-body);
  text-align: left;
  background-color: var(--bg-body);
  letter-spacing: 0;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  body {
    font-size: var(--font-13);
  }
}

body {
  margin: 0;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
  --font-body: 16px;
  --font-caption: 14px;
  --font-odds: 16px;
  --font-small: 12px;
  --font-13: 13px;
  --font-bold: bold;
  --text-red: #FC4242;
  --bg-tabs: #03b37f33;
  --back: #72BBEF;
  --back-hover: #4FA1DC;
  --lay: #F994BA;
  --lay-hover: #E16F9A;
  --bg-body: #23292E;
  --text-body: #AAAFB5;
  --bg-table: #2E3439;
  --text-table: #AAAFB5;
  --bg-table-header: #3c444b;
  --text-fancy: #FDCF13;
}

.casino-detail {
  padding: 5px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-detail {
    padding: 5px;
  }
}

.casino-detail {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.playerabox {
  width: 39%;
}

.playerabcardbox {
  width: 20%;
  background-color: var(--bg-tabs);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 165px;
  border-radius: 8px;
}

.playerbbox {
  width: 39%;
}

.casino-box-row {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding: 2px 0;
  align-items: center;
  position: relative;
}

.playerafabcy {
  padding: 6px;
  padding-bottom: 0;
  border: 2px solid var(--text-red);
  background-color: #fc42422e;
  min-height: 72px;
}

.mt-2 {
  margin-top: .5rem !important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.w-100 {
  width: 100% !important;
}

.row.row5 {
  margin-left: -5px;
  margin-right: -5px;
}

.playerbfabcy {
  padding: 6px;
  padding-bottom: 0;
  border: 2px solid var(--text-fancy);
  background-color: #fdcf132e;
  min-height: 72px;
}

.casino-nation-name {
  width: calc(100% - 148px);
  background-color: #3c444b85;
  color: #aaafb5;
  padding: 4px;
  position: relative;
}

.casino-nation-name {
  width: 50%;
  padding-right: 10px;
  position: relative;
  background-color: transparent;
}

.casino-bl-box {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.casino-bl-box {
  width: 50%;
}

.text-center {
  text-align: center !important;
}

.poker1dayother .casino-bl-box {
  width: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}

.playerabcardbox img {
  width: 40px;
  margin-right: 5px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .playerabcardbox img {
    width: 22px;
  }
}

.playerabcardbox .poker-icon img {
  width: 120px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .playerabcardbox .poker-icon img {
    width: 90px;
  }
}

.col-12,
.col-md-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.row.row5>[class*="col-"],
.row.row5>[class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.text-right {
  text-align: right !important;
}

b {
  font-weight: bolder;
}

.back {
  background-color: var(--back);
}

.suspended {
  position: relative;
  pointer-events: none;
}

.casino-bl-box-item {
  width: 72px;
  margin-right: 4px;
  border-radius: 4px;
  color: var(--text-table);
  text-align: center;
  height: 32px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.casino-bl-box-item {
  width: calc(50% - 2px);
}

.back {
  background-color: #72bbef40;
  border: 2px solid var(--back);
  color: #d7d7d7;
}

.suspended:before {
  content: "";
  background-image: url(https://sitethemedata.com/v108/static/front/img/lock.svg);
  background-size: 17px 17px;
  filter: invert(1);
  background-repeat: no-repeat;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: center;
  pointer-events: none;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
}

.suspended:after {
  content: "";
  background-color: #373636d6;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.back:hover {
  background-color: var(--back-hover);
}

.back:hover {
  border: 1px solid var(--back);
}

.lay {
  background-color: var(--lay);
}

.casino-bl-box-item:last-child {
  margin-right: 0;
}

.lay {
  background-color: #f994ba40;
  border: 2px solid var(--lay);
  color: #d7d7d7;
}

.lay:hover {
  background-color: var(--lay-hover);
}

.lay:hover {
  border: 1px solid var(--lay);
}

.d-none {
  display: none !important;
}

.poker1dayother .casino-bl-box-item {
  width: calc(50% - 4px);
  color: var(--text-table);
  height: 56px;
  padding: 0;
}

.dealer-name {
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .dealer-name {
    font-size: var(--font-caption);
  }
}

.dealer-name.playera {
  color: var(--text-red);
}

.mt-1 {
  margin-top: .25rem !important;
}

.dealer-name.playerb {
  color: var(--text-fancy);
}

.casino-bl-box-item>span {
  display: block;
  width: 100%;
  line-height: 14px;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-bl-box-item span {
    font-size: var(--font-small);
  }
}

.casino-bl-box-item .casino-box-odd {
  font-weight: var(--font-bold);
  font-size: var(--font-odds);
  height: 16px;
  line-height: 16px;
  margin-bottom: 2px;
  width: 100%;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-bl-box-item .casino-box-odd {
    font-size: var(--font-caption);
  }
}

@import url("//fonts.googleapis.com/css2?family=Noto+Sans");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

* {
  box-sizing: border-box;
  outline: 0 !important;
}

.casino-table {
  background-color: var(--bg-table);
  border-radius: 8px;
  padding-bottom: 0;
  color: var(--text-table);
  position: relative;
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.casino-container {
  width: 100%;
  min-height: calc(100vh - 100px);
}

.casino-center {
  width: calc(100% - 476px);
  margin-right: 6px;
  margin-left: 6px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .casino-center {
    width: calc(100% - 396px);
  }
}

.center-main-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding-top: 6px;
  margin-top: 60px;
}

.main-container {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .main-container {
    width: 100%;
  }
}

.sidebar-left~.main-container {
  width: calc(100% - 302px);
  margin-left: 302px;
  margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .sidebar-left~.main-container {
    width: calc(100% - 220px);
    margin-left: 220px;
  }
}

.wrapper {
  display: flex;
  display: -webkit-flex;
  position: relative;
}

body {
  margin: 0;
  font-family: "Noto Sans";
  font-size: var(--font-body);
  font-weight: 400;
  line-height: 22px;
  color: var(--text-body);
  text-align: left;
  background-color: var(--bg-body);
  letter-spacing: 0;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  body {
    font-size: var(--font-13);
  }
}

body {
  margin: 0;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
  --font-body: 16px;
  --font-caption: 14px;
  --font-odds: 16px;
  --font-small: 12px;
  --font-13: 13px;
  --font-bold: bold;
  --text-yellow: #FDCF13;
  --text-red: #FC4242;
  --back: #72BBEF;
  --back-hover: #4FA1DC;
  --lay: #F994BA;
  --lay-hover: #E16F9A;
  --bg-body: #23292E;
  --text-body: #AAAFB5;
  --bg-table: #2E3439;
  --text-table: #AAAFB5;
  --bg-table-header: #3c444b;
}

.casino-detail {
  padding: 5px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-detail {
    padding: 5px;
  }
}

.casino-detail {
  background: linear-gradient(90deg, rgb(80 59 49) 0, rgb(85 67 56) 20%, rgb(86 70 57) 40%, rgb(86 68 55) 60%, rgb(74 60 53) 80%, rgb(64 50 52) 100%);
}

*,
:after,
:before {
  box-sizing: border-box;
}

.teen1daycasino-container {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.teen1dayleft {
  width: 49%;
}

.teen1daycenter {
  width: 2px;
  background-color: grey;
}

.teen1dayright {
  width: 49%;
}

.teen1dayother {
  width: 100%;
  margin-top: 15px;
}

.casino-box-row {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding: 2px 0;
  align-items: center;
  position: relative;
}

.casino-nation-name {
  width: calc(100% - 148px);
  background-color: #3c444b85;
  color: #aaafb5;
  padding: 4px;
  position: relative;
}

.casino-nation-name {
  width: 50%;
  padding-right: 10px;
  position: relative;
  background-color: #00000047;
  color: #eee !important;
}

.casino-detail .casino-nation-name.no-border {
  background-color: transparent;
  padding: 0;
}

.casino-nation-name.no-border {
  background-color: transparent !important;
}

.casino-bl-box {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.casino-bl-box {
  width: 50%;
}

.teen1dayother .casino-nation-name {
  width: 22%;
}

.teen1dayother .casino-bl-box {
  width: 13%;
}

.casino-bl-box-title .playera {
  color: var(--text-red) !important;
}

.casino-bl-box-item {
  width: 72px;
  margin-right: 4px;
  border-radius: 4px;
  color: var(--text-table);
  text-align: center;
  height: 32px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.casino-bl-box-title .casino-bl-box-item {
  color: var(--text-table);
  height: 24px !important;
  font-size: var(--font-caption);
  flex-direction: row;
}

.casino-bl-box-item {
  width: calc(50% - 2px);
  color: #eee !important;
}

.casino-bl-box-item:last-child {
  margin-right: 0;
}

b {
  font-weight: bolder;
}

.float-right {
  float: right !important;
}

.back {
  background-color: var(--back);
}

.back {
  background-color: #72bbef40;
  border: 2px solid var(--back);
  color: #d7d7d7;
}

.back:hover {
  background-color: var(--back-hover);
}

.back:hover {
  border: 1px solid var(--back);
}

.lay {
  background-color: var(--lay);
}

.lay {
  background-color: #f994ba40;
  border: 2px solid var(--lay);
  color: #d7d7d7;
}

.lay:hover {
  background-color: var(--lay-hover);
}

.lay:hover {
  border: 1px solid var(--lay);
}

.casino-bl-box-title .playerb {
  color: var(--text-yellow) !important;
}

.teen1dayother .casino-bl-box-item {
  width: calc(100% - 4px);
  height: 40px;
}

.suspended {
  position: relative;
  pointer-events: none;
}

.suspended:before {
  content: "";
  background-image: url(https://sitethemedata.com/v108/static/front/img/lock.svg);
  background-size: 17px 17px;
  filter: invert(1);
  background-repeat: no-repeat;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: center;
  pointer-events: none;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
}

.suspended:after {
  content: "";
  background-color: #373636d6;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.d-none {
  display: none !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.casino-bl-box-item>span {
  display: block;
  width: 100%;
  line-height: 14px;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-bl-box-item span {
    font-size: var(--font-small);
  }
}

.casino-bl-box-item .casino-box-odd {
  font-weight: var(--font-bold);
  font-size: var(--font-odds);
  height: 16px;
  line-height: 16px;
  margin-bottom: 2px;
  width: 100%;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-bl-box-item .casino-box-odd {
    font-size: var(--font-caption);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
  box-sizing: border-box;
  outline: 0 !important;
}

.casino-table {
  background-color: var(--bg-table);
  border-radius: 8px;
  padding-bottom: 0;
  color: var(--text-table);
  position: relative;
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.casino-container {
  width: 100%;
  min-height: calc(100vh - 100px);
}

.casino-center {
  width: calc(100% - 476px);
  margin-right: 6px;
  margin-left: 6px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .casino-center {
    width: calc(100% - 396px);
  }
}

.center-main-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding-top: 6px;
  margin-top: 60px;
}

.main-container {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .main-container {
    width: 100%;
  }
}

.sidebar-left~.main-container {
  width: calc(100% - 302px);
  margin-left: 302px;
  margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .sidebar-left~.main-container {
    width: calc(100% - 220px);
    margin-left: 220px;
  }
}

.wrapper {
  display: flex;
  display: -webkit-flex;
  position: relative;
}

body {
  margin: 0;
  font-family: "Noto Sans";
  font-size: var(--font-body);
  font-weight: 400;
  line-height: 22px;
  color: var(--text-body);
  text-align: left;
  background-color: var(--bg-body);
  letter-spacing: 0;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  body {
    font-size: var(--font-13);
  }
}

body {
  margin: 0;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
  --font-body: 16px;
  --font-caption: 14px;
  --font-odds: 16px;
  --font-small: 12px;
  --font-13: 13px;
  --font-bold: bold;
  --text-white: #ffffff;
  --bg-success: #03B37F;
  --back: #72BBEF;
  --back-hover: #4FA1DC;
  --lay: #F994BA;
  --lay-hover: #E16F9A;
  --bg-body: #23292E;
  --text-body: #AAAFB5;
  --bg-table: #2E3439;
  --text-table: #AAAFB5;
  --bg-table-header: #3c444b;
}

.casino-detail {
  padding: 5px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-detail {
    padding: 5px;
  }
}

*,
:after,
:before {
  box-sizing: border-box;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.casino-remark {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  background-color: var(--bg-success);
  color: var(--text-white);
  border-radius: 16px;
  height: 32px;
  width: 100%;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.casino-remark .remark-icon {
  width: 50px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: var(--bg-success);
}

:host {
  display: inline-block;
  overflow: hidden;
  text-align: initial;
  white-space: nowrap;
}

.casino-remark marquee {
  width: calc(100% - 60px);
  float: right;
  padding-left: 10px;
}

.col-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

img {
  vertical-align: middle;
  border-style: none;
}

.casino-remark .remark-icon img {
  height: 20px;
}

.casino-box-row {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding: 2px 0;
  align-items: center;
  position: relative;
}

.casino-nation-name {
  width: calc(100% - 148px);
  background-color: #3c444b85;
  color: #aaafb5;
  padding: 4px;
  position: relative;
}

.casino-nation-name {
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.casino-bl-box {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.casino-bl-box {
  width: 100%;
}

.d-none {
  display: none !important;
}

b {
  font-weight: bolder;
}

.back {
  background-color: var(--back);
}

.casino-bl-box-item {
  width: 72px;
  margin-right: 4px;
  border-radius: 4px;
  color: var(--text-table);
  text-align: center;
  height: 32px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.casino-bl-box-item {
  width: calc(50% - 2px);
  height: 48px;
}

.back {
  background-color: #72bbef40;
  border: 2px solid var(--back);
  color: #d7d7d7;
}

.back:hover {
  background-color: var(--back-hover);
}

.back:hover {
  border: 1px solid var(--back);
}

.lay {
  background-color: var(--lay);
}

.casino-bl-box-item:last-child {
  margin-right: 0;
}

.lay {
  background-color: #f994ba40;
  border: 2px solid var(--lay);
  color: #d7d7d7;
}

.lay:hover {
  background-color: var(--lay-hover);
}

.lay:hover {
  border: 1px solid var(--lay);
}

.casino-bl-box-item>span {
  display: block;
  width: 100%;
  line-height: 14px;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-bl-box-item span {
    font-size: var(--font-small);
  }
}

.casino-bl-box-item .casino-box-odd {
  font-weight: var(--font-bold);
  font-size: var(--font-odds);
  height: 16px;
  line-height: 16px;
  margin-bottom: 2px;
  width: 100%;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-bl-box-item .casino-box-odd {
    font-size: var(--font-caption);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans");

* {
  box-sizing: border-box;
  outline: 0 !important;
}

.casino-table {
  background-color: var(--bg-table);
  border-radius: 8px;
  padding-bottom: 0;
  color: var(--text-table);
  position: relative;
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.casino-container {
  width: 100%;
  min-height: calc(100vh - 100px);
}

.casino-center {
  width: calc(100% - 476px);
  margin-right: 6px;
  margin-left: 6px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .casino-center {
    width: calc(100% - 396px);
  }
}

.center-main-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding-top: 6px;
  margin-top: 60px;
}

.main-container {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .main-container {
    width: 100%;
  }
}

.sidebar-left~.main-container {
  width: calc(100% - 302px);
  margin-left: 302px;
  margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .sidebar-left~.main-container {
    width: calc(100% - 220px);
    margin-left: 220px;
  }
}

.wrapper {
  display: flex;
  display: -webkit-flex;
  position: relative;
}

body {
  margin: 0;
  font-family: "Noto Sans";
  font-size: var(--font-body);
  font-weight: 400;
  line-height: 22px;
  color: var(--text-body);
  text-align: left;
  background-color: var(--bg-body);
  letter-spacing: 0;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  body {
    font-size: var(--font-13);
  }
}

body {
  margin: 0;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
  --font-body: 16px;
  --font-caption: 14px;
  --font-odds: 16px;
  --font-small: 12px;
  --font-13: 13px;
  --font-bold: bold;
  --bg-tabs: #03b37f33;
  --back: #72BBEF;
  --back-hover: #4FA1DC;
  --bg-body: #23292E;
  --text-body: #AAAFB5;
  --bg-table: #2E3439;
  --text-table: #AAAFB5;
  --bg-table-header: #3c444b;
  --text-table-header: #AAAFB5;
  --text-highlight: #ffffff;
  --text-fancy: #FDCF13;
}

.casino-detail {
  padding: 5px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-detail {
    padding: 5px;
  }
}

*,
:after,
:before {
  box-sizing: border-box;
}




@media only screen and (min-width: 768px) {
  .d-none-big {
    display: none !important;
  }
}



.teen20casino-container {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.teen20casino-container {
  padding-top: 10px;
}



.teen20left {
  width: 49%;
}

.teen20left {
  display: flex;
  flex-wrap: wrap;
}

.teen20center {
  width: 2px;
  background-color: grey;
}

.teen20right {
  width: 49%;
}

.teen20right {
  display: flex;
  flex-wrap: wrap;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  text-align: center !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.casino-cards {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}



.row.row5 {
  margin-left: -5px;
  margin-right: -5px;
}


.ab2-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
  margin-right: 2%;
  color: var(--text-highlight);
  height: 60px;
}

.pr {
  position: relative;
}

.suspended {
  position: relative;
  pointer-events: none;
}

.sa-sb-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-fancy);
  width: 20%;
  margin-right: 2%;
  color: var(--text-highlight);
  height: 60px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.suspended:before {
  content: "";
  background-image: url(https://sitethemedata.com/v108/static/front/img/lock.svg);
  background-size: 17px 17px;
  filter: invert(1);
  background-repeat: no-repeat;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: center;
  pointer-events: none;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
}

.suspended:after {
  content: "";
  background-color: #373636d6;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.sa-sb-box:hover {
  border: 2px solid var(--text-fancy);
}

.ab2-bet {
  background-color: var(--bg-tabs);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-fancy);
  width: 30%;
  margin-right: 2%;
  color: var(--text-highlight);
  height: 60px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.ab2-bet:hover {
  border: 2px solid var(--text-fancy);
}

.casino-box-row {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding: 2px 0;
  align-items: center;
  position: relative;
}

.ab2oddeven .casino-box-row {
  width: 100%;
}

.ab2cards .casino-box-row {
  width: 100%;
}

.casino-bl-box {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.casino-cards-odds-title {
  background-color: var(--bg-table-header);
  color: var(--text-table-header);
}

.casino-card-item {
  margin-right: 5px;
  width: auto;
  margin-bottom: 5px;
  min-width: 60px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-card-item {
    min-width: 50px;
  }
}

.casino-card-item:last-child {
  margin-right: 0;
}

.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.row.row5>[class*="col-"],
.row.row5>[class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.col-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.d-none {
  display: none !important;
}

.ab-book {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.ab2oddeven .casino-bl-box {
  width: 50%;
}

.ab2cards .casino-bl-box {
  width: 25%;
}

.casino-bl-box-item {
  width: 72px;
  margin-right: 4px;
  border-radius: 4px;
  color: var(--text-table);
  text-align: center;
  height: 32px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.casino-bl-box-item:last-child {
  margin-right: 0;
}

.casino-card-item .card-image {
  display: inline-block;
  cursor: pointer;
}

.card-image.suspended:after {
  border-radius: 0;
}

.casino-book {
  text-align: center;
  font-size: var(--font-caption);
  line-height: 18px;
  color: var(--text-highlight);
  margin-top: 2px;
}

.ab2oddeven .casino-bl-box-item {
  width: calc(100% - 8px);
  height: 46px;
}

.ab2oddeven .casino-box-row:first-child .casino-bl-box-item {
  height: auto;
}

.back {
  background-color: var(--back);
}

.back {
  background-color: #72bbef40;
  border: 2px solid var(--back);
  color: #d7d7d7;
}

.back:hover {
  background-color: var(--back-hover);
}

.back:hover {
  border: 1px solid var(--back);
}

.ab2cards .casino-bl-box-item {
  width: calc(100% - 4px);
  height: 46px;
}

b {
  font-weight: bolder;
}

img {
  vertical-align: middle;
  border-style: none;
}

.casino-card-item .card-image img {
  width: 50px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-card-item .card-image img {
    width: 40px;
  }
}

.col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}







.casino-bl-box-item>span {
  display: block;
  width: 100%;
  line-height: 14px;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-bl-box-item span {
    font-size: var(--font-small);
  }
}

.casino-bl-box-item .casino-box-odd {
  font-weight: var(--font-bold);
  font-size: var(--font-odds);
  height: 16px;
  line-height: 16px;
  margin-bottom: 2px;
  width: 100%;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .casino-bl-box-item .casino-box-odd {
    font-size: var(--font-caption);
  }
}

.casino-card-img img {
  width: 30px;
  height: auto;
  margin-left: 5px;
}

.casino-card-img img:last-child {
  margin-left: 0;
}

.col-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.owl-carousel {
  display: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  width: 100%;
  z-index: 1;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.ab-slider {
  max-width: 85%;
  position: relative;
  margin: 0 auto;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  margin-top: 0;
}

.owl-carousel .owl-nav.disabled {
  display: none;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 10px;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

button {
  border-radius: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  text-transform: none;
}

[type="button"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-carousel .owl-nav button.owl-prev {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  position: absolute;
  top: 0;
  height: 100%;
  width: 30px;
  font-size: 30px;
  right: -22px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
  background-color: transparent;
}

.owl-carousel .owl-nav .owl-next {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  position: absolute;
  top: 0;
  height: 100%;
  width: 30px;
  font-size: 30px;
  left: -22px;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-WRiKU.style-WRiKU {
  transform: translate3d(0px, 0px, 0px);
  transition: all 0s ease 0s;
  width: 2px;
}

#style-4Yn2D.style-4Yn2D {
  width: auto;
  margin-left: 2px;
}

#style-KVD9Q.style-KVD9Q {
  transform: translate3d(0px, 0px, 0px);
  transition: all 0s ease 0s;
  width: 2px;
}

#style-AbmeP.style-AbmeP {
  width: auto;
  margin-left: 2px;
}