@import url("//fonts.googleapis.com/css2?family=Noto+Sans");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .casino-center {
        width: calc(100% - 396px);
    }
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .main-container {
        width: 100%;
    }
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .sidebar-left~.main-container {
        width: calc(100% - 220px);
        margin-left: 220px;
    }
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    body {
        font-size: var(--font-13);
    }
}

body {
    margin: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-13: 13px;
    --text-white: #ffffff;
    --text-black: #000000;
    --bg-success: #03B37F;
    --back: #72BBEF;
    --back-hover: #4FA1DC;
    --lay: #F994BA;
    --lay-hover: #E16F9A;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
}

.casino-detail {
    padding: 5px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .casino-detail {
        padding: 5px;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

.teen20casino-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.teen20left {
    width: 49%;
}

.teen20right {
    width: 49%;
}

.score-box {
    position: relative;
    height: 64px;
    margin-top: 50px;
    padding: 0;
    background-image: url("../../../../assets/score-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 45px;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;

}

.casino-remark {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    background-color: var(--bg-success);
    color: var(--text-white);
    border-radius: 16px;
    height: 32px;
    width: 100%;
}

.casino-remark {
    margin-top: 62px;
    margin-bottom: 45px;
}

.team-score {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: var(--text-white);
}

.ball-icon {
    position: absolute;
    left: 50%;
    top: -25px;
    height: 50px;
    transform: translateX(-50%);
}

.suspended {
    position: relative;
    pointer-events: none;
}

.blbox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    width: 170px;
    height: 40px;
    transform: translateX(-50%);
    bottom: -15px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .blbox {
        width: 150px;
    }
}

.suspended:before {
    content: "";
    background-image: url(https://sitethemedata.com/v108/static/front/img/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    pointer-events: none;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
}

.suspended:after {
    content: "";
    background-color: #373636d6;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: not-allowed;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.suspended:after {
    border-radius: 0;
}

.casino-remark .remark-icon {
    width: 50px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: var(--bg-success);
}

:host {
    display: inline-block;
    overflow: hidden;
    text-align: initial;
    white-space: nowrap;
}

.casino-remark marquee {
    width: calc(100% - 60px);
    float: right;
    padding-left: 10px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.ball-icon img {
    height: 60px;
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    .ball-icon img {
        height: 50px;
    }
}

.back {
    background-color: var(--back);
}

.blbox div {
    width: 50%;
    text-align: center;
    color: var(--text-white);
    height: 40px;
    line-height: 40px;
    color: var(--text-black);
}

.back {
    /* background-color: #72bbef40;
border: 2px solid var(--back); */
    color: #d7d7d7;
}

.blbox .back {
    border: 0;
    background-color: var(--back);
    cursor: pointer;
    font-weight: 700;
    color: var(--text-black);
}

.back:hover {
    background-color: var(--back-hover);
}

.back:hover {
    border: 1px solid var(--back);
}

.blbox .back,
.blbox .back:hover {
    border: 0;
    background-color: var(--back);
    cursor: pointer;
    font-weight: 700;
    color: var(--text-black);
}

.lay {
    background-color: var(--lay);
}

.lay {
    background-color: #f994ba40;
    border: 2px solid var(--lay);
    color: #d7d7d7;
}

.blbox .lay {
    border: 0;
    background-color: var(--lay);
    cursor: pointer;
    font-weight: 700;
    color: var(--text-black);
}

.lay:hover {
    background-color: var(--lay-hover);
}

.lay:hover {
    border: 1px solid var(--lay);
}

.blbox .lay,
.blbox .lay:hover {
    border: 0;
    background-color: var(--lay);
    cursor: pointer;
    font-weight: 700;
    color: var(--text-black);
}

.casino-remark .remark-icon img {
    height: 20px;
    width: 21px;
}

.text-center {
    text-align: center !important;
}

.d-block {
    display: block !important;
}

b {
    font-weight: bolder;
}

.ml-1 {
    margin-left: .25rem !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

    .andar-bahar2 .teen20left,
    .andar-bahar2 .teen20right {
        width: 100%;
    }
}