@import url("//fonts.googleapis.com/css2?family=Noto+Sans");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

* {
    box-sizing: border-box;
    outline: 0 !important;
}

footer {
    display: block;
}

.footer {
    margin-top: 0;
    padding: 8px 0;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.fullwidthdetail-container .footer {
    width: calc(100% - 476px);
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .fullwidthdetail-container .footer {
        width: calc(100% - 384px);
    }
}

.fullwidthdetail-container {
    width: 100%;
    position: relative;
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .main-container {
        width: 100%;
    }
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
    .sidebar-left~.main-container {
        width: calc(100% - 220px);
        margin-left: 220px;
    }
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
    body {
        font-size: var(--font-13);
    }
}

body {
    margin: 0;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --font-13: 13px;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-sidebar: #16191C;
    --text-sidebar: #AAAFB5;
    --bg-table: #2E3439;
    --bg-table-header: #3c444b;
}

.support {
    width: 100%;
    display: flex;
    background-image: linear-gradient(#2c8268, var(--bg-sidebar));
    padding: 10px;
    justify-content: center;
    color: var(--text-sidebar);
    border-radius: 0;
    position: relative;
    flex-wrap: wrap;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.footer .support>div:first-child {
    flex: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.footer-social {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

}

.w-100 {
    width: 100% !important;
}

.text-center {
    text-align: center !important;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a,
a:hover {
    text-decoration: none;
}

b {
    font-weight: bolder;
}

.footer .support>div:first-child b {
    font-size: 20px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.footer-social img {
    margin-left: 5px;
    height: 30px;
}