body {
  .dt-20-grid-odds-main {
    grid-gap: 0;

    .dt-20-odds-col {
      padding: 0 3px;
    }
  }

  .andar-cards-grid {
    display: inherit;

    .andar-cards-box {
      display: block;
      padding: 0;

      .andar-text {
        color: #fc4242;
      }

      .bahar-text,
      .andar-text {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 400;
      }

      .andar-group-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .andar-grid-item {
          width: 15%;
          margin: 0 5px 10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  body {
    .aaa-grid-main {
      display: flex;
      flex-direction: column;

      .aaa-grid-top {
        display: flex;
        flex-direction: column;

        .aaa-grid-item-top {
          display: flex;
          align-items: center;
          padding: 0;

          .chakra-text {
            flex: 1;
            text-align: left;
            background-color: #333;
            padding: 5px 5px 5px 15px;
          }

          .casinoButton,
          .casinoButton-pink {
            width: 40%;
            height: 40px !important;
          }
        }
      }

      .aaa-grid-three {
        display: flex;
        flex-direction: column;

        .casinoButton,
        .casinoButton-pink {
          height: 40px !important;
        }
      }

      .aaa-grid-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 15px;

        .aaa-grid-item {
          min-width: 70px;
          margin: 5px;
        }
      }
    }

    .d-none-mobile {
      display: none !important;
    }

    .bollywood-casion-main {
      .casino-nation-name {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
      }

      .row5 {
        flex-direction: column;

        .col-4 {
          width: 100%;
          flex: 1;
          max-width: 100%;
        }
      }

      .row5.bottom-row {
        flex-direction: row;

        .casino-box-row {
          flex-direction: column;
          text-align: center;
          justify-content: center;
          align-items: center;

          .casino-nation-name {
            justify-content: center;
          }
        }

        .col-lg-6 {
          .casino-box-row {
            flex-direction: row;
          }
        }
      }
    }

    .casino-card-item {
      min-width: 70px;

      .card-image img {
        width: 35px;
      }
    }

    .teen20right {
      padding-top: 25px;
    }

    .teen20left,
    .teen20right {
      width: 100%;
    }

    .a-b-grid-main {
      display: flex;
      flex-wrap: wrap;

      .andar-cards-grid {
        width: 100%;
      }
    }

    .dt-grid-top-head {
      width: 100%;

      .dt-grid-col-left,
      .dt-grid-col-right {
        width: 35%;
      }

      .dt-grid-col-left,
      .dt-grid-col-center,
      .dt-grid-col-right {
        display: flex;
        flex-direction: column;
        padding: 5px;

        .css-1heqzl,
        .css-1o4mfjc {
          padding: 0;
        }

        p {
          padding: 0;
        }
      }
    }

    .dtd-grid-main {
      flex-wrap: wrap;

      .dtd-grid-col {
        width: 100%;
      }
    }

    .content-box {
      margin-top: 20px;
    }

    // .card-grid-main{
    //     display: flex;
    //     flex-direction: column;
    //     width: 60%;
    // }
    .grid-mobile-size {
      display: flex;
      flex-direction: column;
      height: auto;

      .grid-col {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .card-col {
          margin: 7px;
        }
      }

      .grid-item-two {
        border-left: 0;
      }
    }

    .casino-box {
      .row5 {
        .col-md-3 {
          width: 50%;
        }
      }
    }
  }

  .grid-btn-group {
    .flex-group-btn {
      flex-wrap: wrap;
      height: auto;

      .dt2-dragonButton,
      .dt2-tigerButton {
        width: 50%;
      }

      .dt2-pairButton {
        width: 100%;
        margin-left: 0;
      }

      .dt2-tieButton {
        width: 80px;
        height: 80px;
        left: 50%;
      }
    }
  }

  .dis-small-teenpatti-1day-show {
    display: flex !important;
  }

  .dis-small-teenpatti-1day-noshow {
    display: none !important;
  }

  .cmeter-card-box {
    .cmeter-card-low {
      display: flex;
      justify-content: flex-start;

      div:nth-child(1) {
        width: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-success {
          margin-left: 0px !important;
        }
      }

      .cmeter-mobile-view {
        display: flex;
        width: 75%;

        span {
          display: inline-block;
        }
      }
    }

    .cmeter-card-high {
      display: flex;
      justify-content: flex-start;

      div:nth-child(1) {
        width: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-success {
          margin-left: 0px !important;
        }
      }

      .cmeter-mobile-view {
        display: flex;
        width: 75%;

        span {
          display: inline-block;
        }
      }
    }
  }

  .teen1daycasino-container {
    flex-direction: column;

    .teen1dayleft {
      width: 100%;
    }

    .teen1dayright {
      width: 100%;
    }
  }

  .casinoButton,
  .casinoButton-pink {
    height: 30px !important;
  }

  // .casino-detail{
  //     background: linear-gradient(90deg,rgb(80 59 49) 0,rgb(85 67 56) 20%,rgb(86 70 57) 40%,rgb(86 68 55) 60%,rgb(74 60 53) 80%,rgb(64 50 52) 100%)!important;
  // }
}

@media (min-width: 768px) {
  body {
    .dt2-tieButton {
      flex: 1;
      min-width: 100px;
      margin-right: -25px;
      position: inherit;
      margin-left: -25px;
      transform: inherit;
    }
  }

  .d-none-web {
    display: none !important;
  }

  .dis-big-teenpatti-1day-show {
    display: flex !important;
  }

  .dis-big-teenpatti-1day-noshow {
    display: none !important;
  }
}
