.loader {
    color: #04af7d;
    font-family: sans-serif;
    height: 30px;

    overflow-x: hidden;
    /* padding-top: 7px; */
    position: relative;
    text-align: center;
    /*  top: 10%;
 transform: translate(-50%, -50%); */
    width: 60px;
}

.loader::before {
    animation: left-to-right 1s alternate infinite linear;
    background-color: #04af7d;
    content: "";
    height: 3px;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 20px;
}

@keyframes left-to-right {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}