@import url('https://fonts.googleapis.com/css?family=Noto+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.eot);
    src: url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.woff2) format("woff2"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.woff) format("woff"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.ttf) format("truetype"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-regular-400.svg#fontawesome) format("svg");
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.eot);
    src: url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.woff2) format("woff2"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.woff) format("woff"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.ttf) format("truetype"), url(https://use.fontawesome.com/releases/v5.7.0/webfonts/fa-solid-900.svg#fontawesome) format("svg");
}

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-video {
    position: relative;
    background-image: linear-gradient(0deg, #100b1e 0%, #160d27 26.42%, #11091d 48.45%, #030309 100%);
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
    flex-wrap: wrap;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
}

.casino-vieo-rules {
    display: none;
    position: absolute;
    right: -200px;
    top: 50px;
    z-index: 10;
    flex-wrap: wrap;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    width: 0;
    height: calc(100% - 160px);
    border-radius: 0;
}

.casino-vieo-rules.show-rules {
    display: flex;
    max-width: calc(100%);
    width: auto;
    z-index: 101;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.casino-vieo-rules .rules-header {
    background-color: #333;
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding: 2px;
    width: 100%;
    height: auto;
}

.casino-vieo-rules .rules-body {
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #666666 #333333;
    height: calc(100% - 30px) !important;
    line-height: normal;
    width: 100%;
}

.casino-vieo-rules .rules-body::-webkit-scrollbar {
    width: 8px;
}

.casino-vieo-rules .rules-body::-webkit-scrollbar-thumb {
    background-color: #333333;
}

.casino-vieo-rules .rules-body::-webkit-scrollbar-track {
    background: #666666;
}

.fas {
    display: inline-block;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

.casino-vieo-rules .rules-header i {
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;
}

.fa-times:before {
    content: "\f00d";
}

.rules-section {
    text-align: left;
    margin-bottom: 10px;
}

.rules-section:-webkit-scrollbar {
    width: 8px;
}

.rules-section:-webkit-scrollbar-thumb {
    background-color: #333333;
}

.rules-section:-webkit-scrollbar-track {
    background: #666666;
}

img {
    vertical-align: middle;
    border-style: none;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.casino-vieo-rules img {
    max-width: 300px;
}


/* These were inline style tags. Uses id+class to override almost everything */
#casino-vieo-rules.style-IJ9dB {
    display: block;
}

.casino-options {
    position: absolute;
    top: 0px;
    right: 10px;
}

.casino-video-right-icons {
    right: 10px;
    position: absolute;
    display: flex;
}

.casino-video-rules-icon,
.casino-video-home-icon {
    margin-left: 10px;
    font-size: x-large;
    margin-top: 10px;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

@font-face {
    font-family: Card Characters;
    src: url(https://sitethemedata.com/v133/static/front/fonts/card.ttf);
}

@import url('https://fonts.googleapis.com/css?family=Noto+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

* {
    box-sizing: border-box;
    outline: 0 !important;
}

.casino-vieo-rules {
    display: none;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 10;
    flex-wrap: wrap;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    width: 0;
    height: calc(100% - 160px);
    border-radius: 0;
}



.casino-video {
    position: relative;
    background-image: linear-gradient(0deg, #100b1e 0%, #160d27 26.42%, #11091d 48.45%, #030309 100%);
}

.casino-table {
    background-color: var(--bg-table);
    border-radius: 8px;
    padding-bottom: 0;
    color: var(--text-table);
    position: relative;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.casino-container {
    width: 100%;
    min-height: calc(100vh - 100px);
}

.casino-center {
    width: calc(100% - 476px);
    margin-right: 6px;
    margin-left: 6px;
    position: relative;
}

.center-main-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-top: 6px;
    margin-top: 60px;
}

.main-container {
    position: relative;
    width: 100%;
}

.sidebar-left~.main-container {
    width: calc(100% - 302px);
    margin-left: 302px;
    margin-top: 34px;
}

.wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
    flex-wrap: wrap;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    font-size: var(--font-body);
    font-weight: 400;
    line-height: 22px;
    color: var(--text-body);
    text-align: left;
    background-color: var(--bg-body);
    letter-spacing: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-table) var(--bg-table-header);
}

:root {
    --font-body: 16px;
    --bg-body: #23292E;
    --text-body: #AAAFB5;
    --bg-table: #2E3439;
    --text-table: #AAAFB5;
    --bg-table-header: #3c444b;
}

.rules-body {
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #666666 #333333;
    height: calc(80vh - 30px) !important;
    line-height: normal;
    width: 100%;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.casino-vieo-rules .rules-body::-webkit-scrollbar {
    width: 8px;
}

.casino-vieo-rules .rules-body::-webkit-scrollbar-thumb {
    background-color: #333333;
}

.casino-vieo-rules .rules-body::-webkit-scrollbar-track {
    background: #666666;
}

.rules-section {
    text-align: left;
    margin-bottom: 10px;
}

.rules-section:-webkit-scrollbar {
    width: 8px;
}

.rules-section:-webkit-scrollbar-thumb {
    background-color: #333333;
}

.rules-section:-webkit-scrollbar-track {
    background: #666666;
}

/* ul {
    margin-top: 0;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
} */
.pr-4 {
    padding-right: 1.5rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.rules-section .list-style {
    list-style: disc;
}

/* li {
    list-style: none;
    padding: 0;
    margin: 0;
} */

.rules-section ul li {
    margin-bottom: 5px;
}

.rules-section .list-style li {
    list-style: disc;
}

/* p {
    margin-top: 0;
    margin-bottom: 1rem;
} */

.rules-section p {
    margin-bottom: 5px;
}

.rules-section .cards-box {
    background: #fff;
    padding: 6px;
    display: inline-block;
    color: #000;
    min-width: 150px;
}

.ml-1 {
    margin-left: .25rem !important;
}

.rules-section .card-character {
    font-family: Card Characters;
}

.rules-section .black-card {
    color: black;
}

.rules-section .red-card {
    color: red;
}